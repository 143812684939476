import { FC, useState } from "react";
import { useFirebaseCachedImageUrl } from "src/helpers/useFirebaseCachedImageUrl";

type Props = {
  uri: string;
  defaultUri?: string;
  secondDefaultUri?: string;
  alt?: string;
  className?: string;
  retries?: number;
  showPlaceholder?: boolean;
};

export const FirebaseCachedImage: FC<Props> = ({
  showPlaceholder,
  className,
  uri,
  defaultUri,
  secondDefaultUri,
  alt,
  retries,
}) => {
  const [loaded, setLoaded] = useState(false);
  const imageUrl = useFirebaseCachedImageUrl(uri, defaultUri, secondDefaultUri, retries);

  return (
    <>
      {!loaded && showPlaceholder && (
        <div className="flex h-full w-full items-center justify-center">
          <div className="flex h-64 w-64 animate-pulse items-center justify-center rounded-md bg-gray-300">
            <div
              className="inline-block h-20 w-20 animate-spin rounded-full border-8 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        </div>
      )}
      <img
        className={`${className} ${loaded ? "block" : "hidden"}`}
        src={imageUrl}
        alt={alt}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};
