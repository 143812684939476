import { useEvent } from "./useEvent";

export const getLocaleFlag = (locale: AppLocale) => {
  switch (locale) {
    case "en":
      return "🇬🇧";
    case "lt":
      return "🇱🇹";
    default:
      return "🇬🇧"; // Default to english
  }
};

export const getBeginCopy = (locale: AppLocale) => {
  switch (locale) {
    case "en":
      return "Begin";
    case "lt":
      return "Pradėti";
    default:
      return "Begin"; // Default to english
  }
};

export const useLocales = (useAllLocales = false) => {
  const [event] = useEvent();
  const locales = event?.locales;
  const validLocales = locales
    ? (Object.keys(locales).filter((locale) => locales[locale] === true) as AppLocale[])
    : ["en"];

  if (useAllLocales) return ["en", "lt"] as AppLocale[];

  return validLocales as AppLocale[];
};
