import { FC, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useFirebaseCachedImageUrl } from "src/helpers/useFirebaseCachedImageUrl";

interface Props {
  eventId: string;
  teamId: string;
  extraId: string;
  onDelete: (imageId: string) => void;
  alt?: string;
}

export const FirebaseCachedDeleteImage: FC<Props> = ({ alt, eventId, extraId, teamId, onDelete }) => {
  const uri = `events/${eventId}/teams/${teamId}/extras/${extraId}_light`;
  const imageUrl = useFirebaseCachedImageUrl(uri, "");
  const [active, setActive] = useState(false);
  const { t } = useTranslation("admin");
  if (!imageUrl) return null;

  return (
    <div
      className={`card ${active ? "image-full" : ""} h-full h-40 max-w-sm  bg-base-200 md:h-96`}
      onClick={() => setActive(!active)}
    >
      <figure className="h-40 md:h-96">
        <img className="h-40 w-full rounded-2xl object-cover md:h-96" src={imageUrl} alt={alt} />
      </figure>

      {active && (
        <div className="card-body h-40 justify-center p-0 md:h-96">
          <div className="card-actions self-center">
            <div>
              <button className={`btn-error btn `} onClick={() => onDelete(extraId)}>
                {t("delete")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
