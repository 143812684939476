import { useContext, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { useToast } from "src/helpers/useToast";
import { Input } from "@components/Input";
import { useForm } from "react-hook-form";
import { sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from "@helpers/useTranslation";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useBranding } from "src/helpers/useBranding";

export const ForgotPassword = () => {
  const { t } = useTranslation("register");
  const toast = useToast();
  const { auth } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await sendPasswordResetEmail(auth, data.email);
      toast(t("resetPasswordEmailSent"), "📧", "success");
      navigate("/login");
    } catch (error) {
      const userNotFound = error.code === "auth/user-not-found";
      const message = userNotFound ? t("userNotFound") : t("somethingWentWrong");
      toast(message, "🤷‍♂️", "error");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const { homepage, logoUrl } = useBranding();

  return (
    <div className="flex flex-col">
      <div className="navbar top-0 z-50 flex justify-between border-b-4 px-2 shadow-lg shadow-base-300">
        <div className="ml-4">
          <a href={homepage}>
            <img className="color-white h-16" src={logoUrl} />
          </a>
        </div>

        <div className="align-end muted mr-2 flex flex-col text-xs">
          <div>{t("noAccountYet")}</div>
          <Link to="/register" className="link self-end">
            {t("register")}
          </Link>
        </div>
      </div>
      <main className="mt-4 flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center  text-4xl font-semibold">{t("forgotPassword")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100">
          <div className="flex justify-center px-8">
            <div className="align-center flex w-full max-w-sm flex-col">
              <Input
                label={t("email")}
                name="email"
                required
                placeholder={t("emailExample")}
                type="email"
                errors={errors}
                register={register}
                requiredMessage={t("fieldRequired")}
                autoComplete="on"
                className="max-w-lg"
              />
              <button
                type="submit"
                disabled={loading}
                className={`btn-primary btn mx-8 mt-12 mb-4 ${loading ? "loading" : ""}`}
              >
                {t("sendResetLink")}
              </button>
            </div>
          </div>
        </form>
        <div className="mt-4 flex justify-center text-xs">
          <Link to="/login">
            <span className="underline">{t("backToLogin")}</span>
          </Link>
        </div>
      </main>
    </div>
  );
};
