import { useEffect, useState } from "react";
import { useIsCamdeed } from "./useIsCamdeed";

const camdeedLogoUrl = process.env.PUBLIC_URL + "/camdeed-logo.png";
const tuokisLogoUrl = process.env.PUBLIC_URL + "/tuokis-logo.png";

export const useBranding = () => {
  const [logoUrl, setLogoUrl] = useState("");
  const isCamdeed = useIsCamdeed();

  useEffect(() => {
    const logoUrl = isCamdeed ? camdeedLogoUrl : tuokisLogoUrl;
    setLogoUrl(logoUrl);
  }, [isCamdeed]);

  const brand = isCamdeed ? "camdeed" : "tuokis";
  const brandName = isCamdeed ? "Camdeed" : "Tuokis";
  const homepage = isCamdeed ? "https://camdeed.com" : "https://tuokis.com";
  const emailDomain = isCamdeed ? "camdeed.com" : "tuokis.com";
  const appDomain = isCamdeed ? "camdeed.app" : "tuokis.app";
  const instaUrl = isCamdeed ? "https://instagram.com/tuokisapp" : "https://instagram.com/tuokisapp";

  return { logoUrl, brand, brandName, homepage, emailDomain, appDomain, instaUrl };
};
