import { Footer } from "@components/Footer";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { useParams } from "react-router";
import { useSimpleDocument } from "src/helpers/useSimpleDocument";
import { useTeamId } from "src/helpers/useTeam";
import { Photo } from "./photo";
import { Quiz } from "./quiz";
import { Greeting } from "./greeting";
import { Video } from "./video";

export const Challenge = () => {
  const { eventId, challengeId } = useParams();
  const teamId = useTeamId();

  const [challenge] = useSimpleDocument<Challenge>(`events/${eventId}/challenges/${challengeId}`);
  const [solution, loading] = useSimpleDocument<Solution>(`events/${eventId}/teams/${teamId}/solutions/${challengeId}`);

  return (
    <div className={`h-auto min-h-screen bg-cover`}>
      <main>
        <TopNav backLink="/challenges" />
        <Loading loading={!challenge || loading}>
          <div className="flex flex-col justify-center">
            {challenge?.type === "photo" && (
              <Photo challenge={challenge} completed={solution?.completed || false} eventId={eventId} teamId={teamId} />
            )}
            {challenge?.type === "quiz" && <Quiz challenge={challenge} solution={solution as QuizSolution} />}
            {challenge?.type === "greeting" && (
              <Greeting
                eventId={eventId}
                teamId={teamId}
                challenge={challenge}
                solution={solution as GreetingSolution}
              />
            )}
            {challenge?.type === "video" && (
              <Video eventId={eventId} teamId={teamId} completed={solution?.completed || false} challenge={challenge} />
            )}
          </div>
        </Loading>
      </main>
      <Footer />
    </div>
  );
};
