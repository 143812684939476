import { ConfirmModal } from "@components/ConfirmModal";
import { ClipboardIcon } from "@components/Icons/Clipboard";
import { Loading } from "@components/Loading";
import { Modal } from "@components/Modal";
import { Timestamp, doc, setDoc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { encodeShareData } from "src/helpers/codec";
import { FirebaseContext } from "src/helpers/firebase";
import { uid } from "src/helpers/uid";
import { useBranding } from "src/helpers/useBranding";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useToast } from "src/helpers/useToast";
import { useForm } from "react-hook-form";
import { DateInput } from "@components/DateInput";

type Props = {
  show: boolean;
  onClose: () => void;
  className?: string;
};

export const EventDateModal: FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();

  const { firestore } = useContext(FirebaseContext);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const dateInputRef = useRef<HTMLInputElement | null>(null);
  const handleShowPicker = () => dateInputRef?.current?.showPicker && dateInputRef.current.showPicker();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ date }) => {
    setLoading(true);
    try {
      const docRef = doc(firestore, `events/${eventId}`);
      await setDoc(docRef, { date, lastModifiedOn: Timestamp.now() }, { merge: true });
      toast(t("updatedSuccessfully"), "✅");
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!show) return null;
  return (
    <main>
      <Modal show={show} onCancel={onClose} size="sm">
        <h3 className="mt-4 text-lg font-bold">{t("eventDateModalTitle")}</h3>
        <div className="flex flex-col">
          <div className="mt-6 flex w-full flex-col items-center px-4">
            <form onSubmit={handleSubmit(onSubmit)} className="h-100 flex w-full max-w-lg flex-col items-center py-4">
              <div className="align-start mb-2 flex flex-row" onClick={() => handleShowPicker()}>
                <DateInput
                  className="text-gray-900"
                  ref={dateInputRef}
                  label={t("dateField")}
                  name="date"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="mb-4 mt-8 flex justify-center">
                <button
                  type="submit"
                  disabled={loading}
                  className={`inline-flex w-full justify-center rounded-md bg-primary px-8 py-3 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                    loading ? "loading" : ""
                  }`}
                  id="submit-button"
                >
                  {t("save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </main>
  );
};
