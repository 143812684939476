import { FirebaseApp, getApps, initializeApp } from "firebase/app";
import { Auth, UserCredential, connectAuthEmulator, getAuth, signInWithCustomToken } from "firebase/auth";
import { Database, getDatabase } from "firebase/database";
import { connectFirestoreEmulator } from "firebase/firestore";
import { Functions, connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { FirebaseStorage, connectStorageEmulator, getStorage } from "firebase/storage";
import { Firestore, getFirestore } from "@firebase/firestore";
import React from "react";

const LOCALHOST = "0.0.0.0";

export interface Firebase {
  instance: FirebaseApp;
  storage: FirebaseStorage;
  auth: Auth;
  db: Database;
  functions: Functions;
  firestore: Firestore;
  pinAuth: (token: string) => Promise<UserCredential>;
}

const getConfig = () => {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    firestoreUrl: process.env.REACT_APP_FIREBASE_FIRESTORE_URL,
  };
};

export const initFirebase = () => {
  const config = getConfig();

  const apps = getApps();
  const alreadyInitialized = apps.length > 0;
  const instance = alreadyInitialized ? apps[0] : initializeApp(config);
  const storage = getStorage(instance);
  const db = getDatabase(instance);
  const functions = getFunctions(instance, "europe-west1");
  const firestore = getFirestore(instance);
  const auth = getAuth(instance);

  const emulate = isEmulationEnabled();
  if (emulate && !alreadyInitialized) connectAuthEmulator(auth, `http://${LOCALHOST}:9099`);
  if (emulate && !alreadyInitialized) connectFunctionsEmulator(functions, LOCALHOST, 5001);
  if (emulate && !alreadyInitialized) connectStorageEmulator(storage, LOCALHOST, 9199);
  if (emulate && !alreadyInitialized) connectFirestoreEmulator(firestore, LOCALHOST, 9090);

  const pinAuth = (token: string) => signInWithCustomToken(auth, token);

  return {
    instance,
    storage,
    auth,
    functions,
    db,
    firestore,
    pinAuth,
  };
};

export const FirebaseContext = React.createContext<Firebase>({} as Firebase);

export const isEmulationEnabled = () => process.env.REACT_APP_FIREBASE_EMULATE === "TRUE";
