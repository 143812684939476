import { useLocation } from "react-router-dom";
import { useEventId } from "src/helpers/useEvent";
import { FooterButton } from "./FooterButton";
import { ChallengesIcon } from "@components/Icons/Challenges";
import { HighscoresIcon } from "@components/Icons/Highscores";
import { UploadIcon } from "@components/Icons/Upload";
import { FC } from "react";

type Props = {
  hideUpload?: boolean;
};

export const Footer: FC<Props> = ({ hideUpload }) => {
  const location = useLocation();
  const eventId = useEventId();

  const highscoresSelected = location.pathname.includes("highscores");
  const challengesSelected = location.pathname.includes("challenges");
  const uploadSelected = location.pathname.includes("upload");
  return (
    <footer className="border-grey bottom-0 z-50 h-16 w-full justify-center border-t-0 px-4 pb-4 text-center">
      <div className="flex h-16 justify-center">
        <div className="btm-nav btm-nav-md">
          <FooterButton active={challengesSelected} href={`/${eventId}/challenges`} data-testid="challenges-footer-btn">
            <ChallengesIcon />
          </FooterButton>
          <FooterButton
            disabled={hideUpload}
            active={uploadSelected}
            href={`/${eventId}/upload`}
            data-testid="upload-footer-btn"
          >
            <UploadIcon />
          </FooterButton>
          <FooterButton active={highscoresSelected} href={`/${eventId}/highscores`} data-testid="highscores-footer-btn">
            <HighscoresIcon />
          </FooterButton>
        </div>
      </div>
    </footer>
  );
};
