import { FC, useEffect, useRef } from "react";
import { useLockBodyScroll } from "src/helpers/useScrollLock";

export type ModalProps = {
  show: boolean;
  onCancel: () => void;
  children?: React.ReactNode;
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
  hideX?: boolean;
  preventClose?: boolean;
};

export const Modal: FC<ModalProps> = ({ show, onCancel, preventClose, children, className, size, hideX }) => {
  size = size || "md";
  const backdropRef = useRef();
  const handlerRef = useRef<() => void>();

  useEffect(() => {
    handlerRef.current = onCancel;
  }, [onCancel]);

  const closeIfBackdropClicked = (e) => {
    if (backdropRef.current === e.target && !preventClose) handlerRef.current && handlerRef.current();
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeIfBackdropClicked);
    return () => {
      document.removeEventListener("mousedown", closeIfBackdropClicked);
    };
  }, []);

  useLockBodyScroll(show);
  if (!show) return null;

  return (
    <div
      className={`${`modal-window fixed inset-0 z-30 animate-modal-fade-in overflow-auto bg-black bg-opacity-40`} ${className}`}
    >
      <div className="min-h-screen px-4 text-center" ref={backdropRef}>
        <div
          className={`my-8 inline-block w-full max-w-${size} transform overflow-hidden rounded-xl border border-2 border-base-200 bg-base-100 p-6 text-left align-middle`}
        >
          {hideX ? null : (
            <label
              onClick={onCancel}
              className="absolute right-2 top-2 mr-2 bg-base-100 text-xl text-neutral opacity-60"
            >
              ✕
            </label>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
