import { FC } from "react";

type Props = {
  loading?: boolean;
};

export const OverlaySpinner: FC<Props> = ({ loading }) => {
  if (!loading) return null;
  return (
    <div className={`fade-in fixed inset-0 z-50 animate-modal-fade-in overflow-auto bg-black bg-opacity-40`}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        <div
          className="inline-block h-20 w-20 animate-spin rounded-full border-8 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        />
      </div>
    </div>
  );
};
