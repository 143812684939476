import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { FC } from "react";
import { formatPoints } from "src/helpers/formatPoints";

interface Props {
  index: number;
  teamKey: string;
  teamName: string;
  points: number;
  active?: boolean;
  eventId?: string;
  compact?: boolean;
}

export const TeamRow: FC<Props> = ({ index, compact, teamName, points, teamKey, active, eventId }) => {
  const isEven = index % 2 === 0;
  return (
    <tr key={teamKey} className={`${active ? "font-semibold" : ""} ${isEven ? "even" : "odd"}`}>
      <td className={`px-4 ${compact ? "py-0" : "py-1"}`}>{index}</td>
      <td
        className={`team-row-name max-w-5xs overflow-x-scroll px-2 sm:max-w-sm md:max-w-md lg:max-w-lg ${
          compact ? "py-0" : "py-1"
        }`}
      >
        {teamName}
      </td>
      <td className={`px-2 ${compact ? "py-0" : "py-2"}`}>
        <div className="itens-center avatar flex h-12 w-12">
          <div className={`mask mask-squircle ${compact ? "m-2 h-8 w-8" : "h-12 w-12"} `}>
            <FirebaseCachedImage
              uri={`events/${eventId}/teams/${teamKey}/avatar_thumb`}
              defaultUri="anonymous.webp"
              alt={teamName}
            />
          </div>
        </div>
      </td>
      <td className={`pr-4 pl-2 ${compact ? "py-0" : "py-1"}`}>{formatPoints(points)}</td>
    </tr>
  );
};
