/**
 * Custom function to extract a frame from a video Blob and return it as an image Blob.
 * @param {Blob} videoBlob - The video blob from which to extract a frame.
 * @param {number} frameTime - The time position in the video (in seconds) from which to extract the frame.
 * @returns {Promise<Blob>} A promise that extracts the frame and resolves with the image Blob.
 */
export const extractFrameFromVideoBlob = (videoBlob: Blob, frameTime = 0.5): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    if (!videoBlob) {
      reject(new Error("No video blob provided"));
      return;
    }

    const videoUrl = URL.createObjectURL(videoBlob);
    const videoElement = document.createElement("video");

    videoElement.preload = "auto"; // Ensure video is preloaded
    videoElement.src = videoUrl;
    videoElement.playsInline = true; // Ensure video is not fullscreen
    videoElement.muted = true; // Mute the video to allow autoplay on iOS
    videoElement.setAttribute("playsinline", "");
    videoElement.setAttribute("webkit-playsinline", "");
    videoElement.load(); // Explicitly load the video

    const handleVideoError = (e: ErrorEvent) => {
      URL.revokeObjectURL(videoUrl); // Clean up
      reject(new Error("Error loading video"));
      console.error("Error loading video:", e);
    };

    videoElement.addEventListener("loadedmetadata", () => {
      // Attempt to play the video to comply with autoplay policies.
      const playPromise = videoElement.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            videoElement.currentTime = frameTime;
            // Playback started successfully.
          })
          .catch((error) => {
            // Autoplay policy prevented playback; may need user gesture.
            console.error("Playback initiation failed:", error);
          });
      }
    });

    videoElement.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (imageBlob) => {
          URL.revokeObjectURL(videoUrl); // Clean up
          resolve(imageBlob);
        },
        "image/jpeg",
        0.95,
      );
    });

    videoElement.addEventListener("error", handleVideoError);
  });
};
