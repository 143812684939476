import { Link } from "@components/Link";
import { useTranslation } from "@helpers/useTranslation";
import { useHasNoPin } from "src/helpers/useHasNoPin";

export const Teams = () => {
  const { t } = useTranslation("teams");
  useHasNoPin();

  const title = t("title");
  const about = t("about");
  const dontHaveTeamYet = t("dontHaveTeamYet");
  const createTeam = t("createTeam");
  const alreadyHaveTeam = t("alreadyHaveTeam");

  return (
    <main className="flex-1 flex-col">
      <div className="flex flex-col px-6 py-4">
        <div>
          <h1 className="mt-12 mb-8 text-center  text-4xl font-semibold">{title}</h1>
          <h3 className="mt-8 mb-8 text-center text-xl font-semibold">{about}</h3>
        </div>
        <div className="text-light mt-16 mb-8 text-center text-lg">{dontHaveTeamYet}</div>
        <div className="mt-16 flex justify-center">
          <Link to="newteam">
            <button className="btn-primary btn mb-4 px-4">{createTeam}</button>
          </Link>
        </div>
        <div className="mt-4 flex justify-center text-sm text-neutral dark:text-slate-400">
          <Link to="selectteam">
            <div className="text-neutral underline">{alreadyHaveTeam}</div>
          </Link>
        </div>
      </div>
    </main>
  );
};
