import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useEvent } from "src/helpers/useEvent";

type Props = {
  onNext: () => void;
};

export const PreviewIntro: FC<Props> = ({ onNext }) => {
  const { t } = useTranslation("intro");
  const input1ref = useRef<HTMLInputElement>(null);
  const input2ref = useRef<HTMLInputElement>(null);
  const input3ref = useRef<HTMLInputElement>(null);
  const input4ref = useRef<HTMLInputElement>(null);
  const refs = [undefined, input1ref, input2ref, input3ref, input4ref];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [invalidPin, setInvalidPin] = useState(false);
  const [event] = useEvent();

  useEffect(() => {
    if (event?.pin) {
      const pin = event.pin.toString();
      setInput1(pin[0]);
      setInput2(pin[1]);
      setInput3(pin[2]);
      setInput4(pin[3]);
    }
  }, [event]);

  const [input1, setInput1] = useState("1");
  const [input2, setInput2] = useState("2");
  const [input3, setInput3] = useState("3");
  const [input4, setInput4] = useState("4");

  const focusNext = (key: string) => {
    // Only valid key enteries are numbers or backspace
    if (key !== "Backspace" && isNaN(parseInt(key))) {
      return;
    }

    // Treat entering last digit the same as clicking submit
    if (currentIndex === 4 && key !== "Backspace") {
      onSubmit();
      return;
    }

    const newIndex = key === "Backspace" ? currentIndex - 1 : currentIndex + 1;
    const newIndexBounded = Math.min(Math.max(newIndex, 1), 4);
    setCurrentIndex(newIndexBounded);
  };

  const focusCurrent = () => {
    if (refs[currentIndex]?.current) {
      refs[currentIndex].current.focus();
      refs[currentIndex].current.select();
    }
  };

  useEffect(() => {
    focusCurrent();
    setInvalidPin(false);
  }, [currentIndex]);

  const onSubmit = async () => onNext();

  return (
    <div className="flex flex-col">
      <main className="flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center  text-4xl font-semibold">{t("title")}</h1>
          <h1 className="mt-16 mb-8 text-center text-2xl font-semibold">{event?.name ?? ""}</h1>
          <div className="w-full px-4 pt-4 text-center">{event?.about || t("about")}</div>
        </div>
        <h2 className="mt-16 mb-8 text-center text-2xl font-semibold">PIN</h2>
        <div className="mt-8 flex justify-center">
          <input
            ref={input1ref}
            aria-label="PIN digit 1"
            maxLength={1}
            type="text"
            inputMode="numeric"
            pattern="[0-9]"
            placeholder=""
            value={input1}
            onFocus={() => setCurrentIndex(1)}
            onChange={(e) => setInput1(e.target.value)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input2ref}
            aria-label="PIN digit 2"
            maxLength={1}
            type="text"
            inputMode="numeric"
            value={input2}
            onChange={(e) => setInput2(e.target.value)}
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(2)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input3ref}
            aria-label="PIN digit 3"
            maxLength={1}
            type="text"
            value={input3}
            onChange={(e) => setInput3(e.target.value)}
            inputMode="numeric"
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(3)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input4ref}
            aria-label="PIN digit 4"
            maxLength={1}
            type="text"
            inputMode="numeric"
            value={input4}
            onChange={(e) => setInput4(e.target.value)}
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(4)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
        </div>
        {invalidPin && (
          <div className="mt-4 flex justify-center">
            <span className="text-xs text-error">{t("invalidPinCode")}</span>
          </div>
        )}
        <div className="mt-20 flex justify-center">
          <button
            disabled={!input1 || !input2 || !input3 || !input4}
            className={`btn-primary btn mb-4 px-24`}
            onClick={() => onSubmit()}
          >
            {t("start")}
          </button>
        </div>
      </main>
    </div>
  );
};
