import { ChallengesIcon } from "@components/Icons/Challenges";
import { HighscoresIcon } from "@components/Icons/Highscores";
import { UploadIcon } from "@components/Icons/Upload";
import { FC } from "react";

type Props = {
  hideUpload?: boolean;
  activePage?: ActivePage;
  goToChallenges: () => void;
  goToHighscores: () => void;
  goToUpload: () => void;
};

type ActivePage = "challenges" | "upload" | "highscores";

export const PreviewFooter: FC<Props> = ({ hideUpload, activePage, goToChallenges, goToHighscores, goToUpload }) => {
  const highscoresSelected = activePage === "highscores";
  const challengesSelected = activePage === "challenges";
  const uploadSelected = activePage === "upload";

  return (
    <footer className="border-grey absolute bottom-0 z-10 h-16 w-full justify-center border-t-0 text-center">
      <div className="flex h-16 justify-center">
        <div className="flex flex w-full w-full flex-row flex-row items-center justify-around">
          <FooterButton active={challengesSelected} onClick={goToChallenges} className="preview-bottom-bar-left">
            <ChallengesIcon />
          </FooterButton>
          <FooterButton disabled={hideUpload} active={uploadSelected} onClick={goToUpload}>
            <UploadIcon />
          </FooterButton>
          <FooterButton active={highscoresSelected} onClick={goToHighscores} className="preview-bottom-bar-right">
            <HighscoresIcon />
          </FooterButton>
        </div>
      </div>
    </footer>
  );
};

type ButtonProps = {
  children: React.ReactNode;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

export const FooterButton: FC<ButtonProps> = ({ children, onClick, active, disabled, className, ...props }) => {
  return (
    <div
      onClick={onClick}
      className={`mt-1 w-full border border-base-300 border-opacity-50 shadow-lg shadow-xl ${
        active ? "bg-base-200" : "bg-base-300 text-opacity-10"
      } ${disabled ? "disabled cursor-not-allowed" : "cursor-pointer"} ${className}`}
      {...props}
    >
      <button className="px-6 py-4" disabled={disabled}>
        {children}
      </button>
    </div>
  );
};
