import { Input } from "@components/Input";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const NewUser = () => {
  const { t } = useTranslation("upload");
  const navigate = useEventNavigate();
  const eventId = useEventId();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { pinAuth, functions } = useContext(FirebaseContext);

  const [appData, setAppData] = usePersistedState();
  const [exchangePinForToken, _, error] = useHttpsCallable(functions, "exchangePinForToken");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error) {
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      console.error(error);
      setLoading(false);
    }
  }, [error]);

  const toast = useToast();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const pin = appData?.pin;
      const result = await exchangePinForToken({
        pin,
        eventId,
        teamName: data.userName,
        isUploader: true,
        isNew: true,
      });
      const token = result?.data as string;
      const res = await pinAuth(token);
      const uid = res.user?.uid;
      setAppData({ ...appData, uid, isUploader: true });

      // Just in case to account for local storage
      await new Promise((resolve) => setTimeout(resolve, 100));

      navigate("/uploader");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <main className="flex-1 overflow-y-scroll p-4">
        <h1 className="mt-16 mb-8 text-center  text-4xl font-semibold">{t("userTitle")}</h1>
        <h1 className="mt-4 mb-8 text-center text-lg">{t("userAbout")}</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="h-100">
          <div className="flex w-full flex-col px-4">
            <div className="align-center mx-auto flex w-full max-w-sm">
              <Input
                autoComplete="off"
                label={t("labelUserName")}
                name="userName"
                required
                placeholder={t("placeholderUserName")}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                className="max-w-md"
              />
            </div>
            <div className="mx-12 mb-12 mt-6 flex justify-center">
              <button disabled={loading} className={`${loading ? "loading" : ""} btn-primary btn mb-4`}>
                {t("create")}
              </button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};
