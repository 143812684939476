import { ShareIcon } from "@components/Icons/Share";
import { FC } from "react";
import { useFirebaseCachedImageUrl } from "src/helpers/useFirebaseCachedImageUrl";

type Props = {
  title?: string;
  text?: string;
  uri: string;
};

export const Share: FC<Props> = ({ title, uri, text }) => {
  const imageUrl = useFirebaseCachedImageUrl(uri);
  const canShare = !!navigator.canShare;

  const handleShare = () => {
    if (!!navigator.canShare && navigator.share && !!imageUrl) {
      navigator
        .share({
          title: title ?? "Share this awesome image!",
          text: text ?? "Check out this amazing picture!",
          url: imageUrl,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log("Share not supported on this browser.");
    }
  };

  if (!canShare) return null;
  return (
    <div className="mb-2 flex w-full flex-row items-end justify-center">
      <button onClick={handleShare} className="flex w-full items-center justify-end font-semibold uppercase">
        <ShareIcon />
      </button>
    </div>
  );
};
