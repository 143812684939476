import { FC, ReactNode } from "react";
import { useToast } from "src/helpers/useToast";
import { Spinner } from "./Spinner";

type Props = {
  loading: boolean;
  error?: Error;
  children: ReactNode;
};

export const Loading: FC<Props> = ({ children, loading, error }) => {
  const toast = useToast();
  if (loading) return <Spinner />;
  if (error) {
    toast("Problem occured", "⚠️", "error");
    return null;
  }
  return <>{children}</>;
};
