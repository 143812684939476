import { useBranding } from "@helpers/useBranding";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  degrade: boolean;
  children: ReactNode;
};

export const TrialDegrate: FC<Props> = ({ degrade, children }) => {
  const { t } = useTranslation("admin");
  const { brandName } = useBranding();
  return (
    <>
      {degrade && (
        <div className="absolute z-50">
          <div className="flex items-center justify-center">
            <span className="pt-12 font-black text-black">{t("upgradeToUnlock", { brandName })}</span>
          </div>
        </div>
      )}
      <div className={`${degrade ? "bg-opacity-40 blur" : ""}`}>{children}</div>
    </>
  );
};
