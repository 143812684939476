import { Input } from "@components/Input";
import { FC, useContext, useEffect } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useToast } from "src/helpers/useToast";

type Props = {
  eventId: string;
  initialDiscountCode?: string;
  onNewDiscount(discountCode: string, discountAmount: number): void;
};

export const DiscountInput: FC<Props> = ({ initialDiscountCode, onNewDiscount, eventId }) => {
  const { t } = useTranslation("admin");

  const { functions } = useContext(FirebaseContext);
  const [checkDiscount, checking, error] = useHttpsCallable<unknown, Payments.DiscountResponse>(
    functions,
    "checkDiscount",
  );
  const onCheckDiscount = async (discountCode: string) => {
    const response = await checkDiscount({ eventId, discountCode });
    if (!response) return;

    const discountAmount = response.data.discountAmount;
    if (discountAmount) {
      onNewDiscount(discountCode, discountAmount);
      toast(t("discountApplied"), "🤑", "success");
    }
  };

  useEffect(() => {
    if (initialDiscountCode) onCheckDiscount(initialDiscountCode);
  }, [initialDiscountCode]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (error) toast(t("invalidDiscountCode"), "🤷‍♂️", "error");
  }, [error]);

  const onSubmit = async (data) => await onCheckDiscount(data.discountCode.trim().toUpperCase());

  const toast = useToast();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full max-w-sm flex-row items-end justify-end">
        <Input
          label={t("discountCode")}
          name="discountCode"
          placeholder={t("discountCodeExample")}
          errors={errors}
          register={register}
          autoComplete="on"
          className="input-sm max-w-lg text-xs"
        />
        <button
          disabled={checking}
          type="submit"
          className={`btn-ghost btn-sm btn ml-4 p-2 ${checking ? "loading" : ""}`}
        >
          {t("apply")}
        </button>
      </div>
    </form>
  );
};
