import { FC, useContext, useEffect } from "react";
//@ts-ignore
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { Picture } from "./Picture";
import { useWindowDimensions } from "src/helpers/useWindowDimensions";
import { useToast } from "src/helpers/useToast";
import { useTranslation } from "@helpers/useTranslation";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { FirebaseContext } from "src/helpers/firebase";
import { downloadAsBlob } from "src/helpers/download";
import { useEventId } from "src/helpers/useEvent";
import { debounce } from "src/helpers/debounce";
import { deleteDoc, deleteField, doc, updateDoc } from "firebase/firestore";

export type Image = {
  uri: string;
  filename: string;
  team: string;
  challenge?: string;
  hasVideo?: boolean;
  videoUrl?: string;
};

type Props = {
  galleryId?: string;
  eventName: string;
  defaultEventId?: string;
  images: Image[];
  setDownloading?: (downloading: boolean) => void;
  allowDownload?: boolean;
  allowDelete?: boolean;
};

export const Swiper: FC<Props> = ({
  galleryId,
  defaultEventId,
  eventName,
  allowDownload,
  allowDelete,
  images,
  setDownloading,
}) => {
  galleryId = galleryId ?? "default-event-gallery";
  const { t } = useTranslation("admin");
  const toast = useToast();
  const eventId = useEventId(defaultEventId);
  const { firestore } = useContext(FirebaseContext);

  const { functions } = useContext(FirebaseContext);
  const [downloadPhoto] = useHttpsCallable(functions, "downloadPhoto");
  const download = async ({ uri, filename }) => {
    if (setDownloading) setDownloading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
      const result = await downloadPhoto({ uri, filename, eventId });
      const url = result?.data as string;
      downloadAsBlob(url, filename);
      toast(t("photoDownloaded"), "📸", "success");
    } catch (error) {
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    } finally {
      if (setDownloading) setDownloading(false);
    }
  };
  const downloadDebounced = debounce(download, 1000);

  const share = (imageUrl: string) => {
    // Native HTML5 sharing. Only works on mobile devices.
    if (!!navigator.canShare && navigator.share && !!imageUrl) {
      navigator
        .share({ title: eventName, text: t("sharePhotoText"), url: imageUrl })
        .then(() => toast(t("photoShared"), "📸", "success"))
        .catch((error) => console.error(`Error occured when trying to share image ${eventId}`, error));
    } else {
      console.warn("Sharing is not supported on this browser.");
    }
  };
  const shareDebounced = debounce(share, 1000);

  const deletePhoto = async (uri: string) => {
    // uri comes in the shape of:
    // "events/${eventId}/teams/${team.id}/solutions/${solutionId}" for solutions
    // "events/${eventId}/teams/${team.id}/extras/${extraId}" for extras
    const components = uri.split("/");
    const isSolution = components[4] === "solutions";
    const isExtra = components[4] === "extras";
    const teamId = components[3];
    const imageId = components[components.length - 1];

    try {
      if (isSolution) {
        // Remove from map field
        const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);
        await updateDoc(teamDocRef, {
          [`solutionsMap.${imageId}`]: deleteField(),
        });

        // Remove from collection (future proofing)
        const docRef = doc(firestore, `events/${eventId}/teams/${teamId}/solutions/${imageId}`);
        await deleteDoc(docRef);
      }

      if (isExtra) {
        // Remove from map field
        const teamDocRef = doc(firestore, `events/${eventId}/teams/${teamId}`);
        await updateDoc(teamDocRef, {
          [`extrasMap.${imageId}`]: deleteField(),
        });

        // Remove from collection (future proofing)
        const docRef = doc(firestore, `events/${eventId}/teams/${teamId}/extras/${imageId}`);
        await deleteDoc(docRef);
      }
    } catch (error) {
      console.error(error);
    }

    toast(t("deleted"), "✏️", "success", 1000);
  };
  const deleteDebounced = debounce(deletePhoto, 1000);

  const { width, height } = useWindowDimensions();
  const maxWidth = 800;
  const cappedWidth = width > maxWidth ? maxWidth : width;

  const isPhonePortrait = () => window.matchMedia("(max-width: 600px) and (orientation: portrait)").matches;

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + galleryId,
      tapAction: "none",
      children: "a",
      pswpModule: () => import("photoswipe"),
      initialZoomLevel: (zoomLevelObject) => {
        if (isPhonePortrait()) {
          return zoomLevelObject.vFill;
        } else {
          return zoomLevelObject.fit;
        }
      },
      secondaryZoomLevel: (zoomLevelObject) => {
        if (isPhonePortrait()) {
          return zoomLevelObject.fit;
        } else {
          return 1;
        }
      },

      maxZoomLevel: 1,
    });
    lightbox.on("uiRegister", () => {
      if (allowDownload) {
        lightbox.pswp.ui.registerElement({
          name: "download-button",
          order: 8,
          isButton: true,
          tagName: "a",

          html: {
            isCustomSVG: true,
            inner:
              '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
            outlineID: "pswp__icn-download",
          },

          onInit: (el, pswp) => {
            pswp.on("change", () => {
              el.addEventListener("click", () => {
                // Below utilises hack to carry image info through alt attribute
                // required due to how the PhotoSwipe library has no easy way to pass this info otherwise
                const { uri, filename } = JSON.parse(pswp.currSlide.data.alt) as Image;
                downloadDebounced({ uri, filename });
              });
            });
          },
        });
      }

      lightbox.pswp.ui.registerElement({
        name: "play-button",
        isButton: true,
        tagName: "a",

        html: {
          isCustomSVG: true,
          inner:
            '<path d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"/>',
          outlineID: "pswp__icn-play",
        },

        onInit: (el, pswp) => {
          pswp.on("change", () => {
            const { hasVideo, videoUrl } = JSON.parse(pswp.currSlide.data.alt) as Image;
            if (hasVideo) el.style.display = `block`;
            if (!hasVideo) el.style.display = "none";

            const backdrop = document.createElement("div");

            const videoPlayer = document.createElement("div");
            videoPlayer.className = `video-player hidden`;

            const video = document.createElement("video");
            videoPlayer.appendChild(video);

            el.addEventListener("click", () => {
              document.body.append(videoPlayer);
              document.body.append(backdrop);

              backdrop.className = "backdrop";

              video.src = videoUrl;
              video.autoplay = true;
              video.controls = true;

              const closeVideoPlayer = () => {
                if (videoPlayer.className.includes("hidden")) {
                  videoPlayer.className = "video-player";
                } else {
                  videoPlayer.remove();
                  backdrop.remove();
                }
              };
              document.addEventListener("click", closeVideoPlayer);
            });
          });
        },
      });

      lightbox.pswp.ui.registerElement({
        name: "share-button",
        order: 8,
        isButton: true,
        tagName: "a",

        html: {
          isCustomSVG: true,
          inner:
            '<path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />',
          outlineID: "pswp__icn-share",
        },

        onInit: (el, pswp) => {
          pswp.on("change", () => {
            el.addEventListener("click", () => {
              shareDebounced(pswp.currSlide.data.src);
            });
          });
        },
      });

      if (allowDelete) {
        lightbox.pswp.ui.registerElement({
          name: "delete-button",
          order: 8,
          isButton: true,
          tagName: "a",

          html: {
            isCustomSVG: true,
            inner:
              '<path  d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z" clipRule="evenodd" />',
            outlineID: "pswp__icn-delete",
          },

          onInit: (el, pswp) => {
            pswp.on("change", () => {
              el.addEventListener("click", () => {
                const { uri } = JSON.parse(pswp.currSlide.data.alt) as Image;
                if (window.confirm(t("areYouSureYouWantToDelete"))) {
                  deleteDebounced(uri);
                  setTimeout(() => pswp.close(), 300);
                }
              });
            });
          },
        });
      }
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <div className="pswp-gallery mt-4 flex justify-center" id={galleryId}>
      <div className="absolute grid grid-cols-2 gap-8 pb-20 md:grid-cols-4">
        {images.map((image) => (
          <div key={`${galleryId}-${image.uri}`}>
            <Picture
              galleryId={galleryId}
              image={image}
              width={cappedWidth * 0.8}
              height={height}
              hasVideo={image.hasVideo}
            />
            <div className="relative -mt-10 flex h-10 w-40 flex-col items-center justify-center  bg-gray-100 opacity-90 lg:w-64">
              <div className="text-sm font-semibold">
                {image.team}
                {image.hasVideo ? " 🍿🎞️" : null}
              </div>
              {image.challenge && <div className="overflow-hidden text-sm font-light">{image.challenge}</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
