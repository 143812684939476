import { FC, useState } from "react";

type Props = {
  url: string;
  alt?: string;
  className?: string;
};

export const InstantImage: FC<Props> = ({ className, url, alt }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <img
      className={`${className} ${loaded ? "block" : "hidden"}`}
      src={url}
      alt={alt}
      onLoad={() => {
        setLoaded(true);
      }}
    />
  );
};
