import { FC } from "react";

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  errors: any;
  register: any;
  hidden?: boolean;
  rows?: number;
  cols?: number;
  className?: string;
}

export const Textarea: FC<Props> = ({
  label,
  name,
  required,
  placeholder,
  hidden,
  requiredMessage,
  errors,
  register,
  rows,
  className,
  cols,
}) => {
  return (
    <div className={`form-control w-full  ${hidden ? "hidden" : ""}`}>
      <label className="label">
        <span className="label-text">
          {label} {required ? <span className="text-warning">*</span> : <></>}
        </span>
      </label>
      <textarea
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        className={`input-bordered textarea w-full bg-slate-100 dark:text-slate-500 ${className ?? ""}`}
        {...register(name, { required })}
      />
      {errors[name] && <span className="pl-1 pt-2 text-xs text-warning">{requiredMessage}</span>}
    </div>
  );
};
