import { ConfirmModal } from "@components/ConfirmModal";
import { FullPicker } from "@components/ImagePicker";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { FirebaseContext } from "@helpers/firebase";
import { useToast } from "@helpers/useToast";
import { useTranslation } from "@helpers/useTranslation";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { deleteObject, ref as storageRef, uploadBytes } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";

export const MyBranding = () => {
  const { t } = useTranslation("admin");
  const { functions, firestore, storage } = useContext(FirebaseContext);
  const [getMyEvents, loading] = useHttpsCallable(functions, "getMyEvents");
  const [myEvents, setMyEvents] = useState<EventResponse[]>([]);

  const [saving, setSaving] = useState(false);
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    getMyEvents().then((result) => {
      setMyEvents(result.data as EventResponse[]);
    });
  }, []);

  const toast = useToast();
  const onSubmit = async () => {
    setSaving(true);
    if (image) {
      for (const event of myEvents) {
        const eventId = event.id;
        const imagePath = `events/${eventId}/branding`;
        const imageRef = storageRef(storage, imagePath);
        await uploadBytes(imageRef, image);

        try {
          // Set boolean flag that this event has branding (for the frontend to know to show the branding instead of the default Camdeed/Tuokis logo)
          const docRef = doc(firestore, `events/${eventId}`);
          await setDoc(docRef, { hasBranding: true, lastModifiedOn: Timestamp.now() }, { merge: true });
        } catch (error) {
          console.error(error);
        }
      }
    }

    setSaving(false);
    toast(t("updated"), "👍", "success", 1500);
  };

  const onDeleteBranding = async () => {
    setSaving(true);
    for (const event of myEvents) {
      try {
        const eventId = event.id;
        const imagePath = `events/${eventId}/branding`;
        const imageRef = storageRef(storage, imagePath);
        await deleteObject(imageRef);

        // Set boolean flag that this event has not got branding
        const docRef = doc(firestore, `events/${eventId}`);
        await setDoc(docRef, { hasBranding: false, lastModifiedOn: Timestamp.now() }, { merge: true });
      } catch (error) {
        if (error.code !== "not-found") return;
        console.error(error);
        toast(t("problemOccured"), "😢", "error");
      } finally {
        setSaving(false);
        toast(t("deleted"), "👍", "success");
      }
    }
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  if (!myEvents.length)
    return (
      <>
        <TopNav isAdmin={true} useAllLocales />
      </>
    );

  // Any one of the events will do, as they all have the same branding
  const eventId = myEvents[0].id;

  return (
    <>
      <TopNav isAdmin={true} useAllLocales showBack />
      <ConfirmModal
        messageCopy={t("deleteBrandingMessage")}
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={onDeleteBranding}
      />
      <main className="flex-1 flex-col">
        <div className="mb-24 flex flex-col items-center py-4 px-6">
          <Loading loading={loading}>
            <div className="flex w-full max-w-lg flex-col">
              <h1 className="mt-12 mb-8 text-center text-4xl font-semibold">{t("myBranding")}</h1>
              <p className="mb-12 text-center text-lg font-light">{t("aboutMyBranding")}</p>
              <Loading loading={saving}>
                <FullPicker
                  defaultImageUri={`events/${eventId}/branding`}
                  getFreshDefault={true}
                  uploadLabel={t("upload")}
                  reuploadLabel={t("reupload")}
                  onNewImage={setImage}
                  allowUpload
                />
                <div className="flex max-w-lg justify-center">
                  <div className="mb-12 mt-8">
                    <button
                      onClick={onSubmit}
                      type="button"
                      disabled={saving}
                      className={`inline-flex w-full justify-center rounded-md bg-primary px-8 py-3 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                        saving ? "loading" : ""
                      }`}
                      id="submit-button"
                    >
                      {t("save")}
                    </button>
                    <div className="mt-8 flex justify-center">
                      <button
                        onClick={() => setShowConfirmModal(true)}
                        type="button"
                        disabled={saving}
                        className={`${saving ? "loading" : ""} btn-outline btn-error btn`}
                      >
                        {t("delete")}
                      </button>
                    </div>
                  </div>
                </div>
              </Loading>
            </div>
          </Loading>
        </div>
      </main>
    </>
  );
};
