import { CloseIcon } from "@components/Icons/Close";
import { PoppersIcon } from "@components/Icons/Poppers";
import { RightIcon } from "@components/Icons/Right";
import { PremiumModal } from "@components/PremiumModal/PremiumModal";
import { FC, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useEvent } from "src/helpers/useEvent";
import { usePersistedState } from "src/helpers/usePersistedState";

export const UpgradeNow: FC = () => {
  const [event, loading, error] = useEvent();
  const isPremium = event?.isPremium || false;
  const isTrial = event?.isTrial || false;
  const { t } = useTranslation("admin");
  const [appData, setAppData] = usePersistedState();
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const close = () => {
    setShowPremiumModal(false);
    setAppData({ ...appData, hideUpgradePopup: false });
  };
  const open = () => setShowPremiumModal(true);

  // Hides permanently for current session
  const hideUpgradeBanner = () => setAppData({ ...appData, hideUpgradePopup: true });
  const hideUpgradePopup = appData?.hideUpgradePopup || false;

  if (isPremium || hideUpgradePopup || error || loading || !event) return null;
  return (
    <>
      {showPremiumModal && <PremiumModal show={showPremiumModal} onClose={close} />}
      <div className="break-inside fade-in fixed bottom-5 left-5 z-20 mb-4 flex max-w-[20rem] animate-delayed-fade-in flex-col justify-between space-y-2 overflow-hidden rounded-xl bg-primary bg-opacity-95 p-4 text-sm text-white">
        <div className="mb-2 flex items-center justify-between">
          <span className="text-xs uppercase text-base-200">{t("unlockPremium")}</span>
          {isTrial ? null : (
            <button onClick={hideUpgradeBanner} className="text-base-200">
              <CloseIcon className="h-5 w-5 text-white" />
            </button>
          )}
        </div>
        <div className="flex flex-row items-center space-x-3">
          <PoppersIcon className="mr-2 w-20" />
          <span className="text-base font-medium">{t("upgradeToPremium")}</span>
        </div>
        <div className="flex items-center justify-between">
          <button
            onClick={open}
            className="mt-2 flex items-center justify-center space-x-1 rounded-lg bg-white px-2 py-1 text-xs font-medium text-black"
          >
            <span> {t("upgradeNow")}</span>
            <RightIcon strokeWidth={2} />
          </button>
        </div>
      </div>
    </>
  );
};
