import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { UploadIcon } from "@components/Icons/Upload";
import { FC, useRef, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { compressImage } from "src/helpers/compress";
import { useEventId } from "src/helpers/useEvent";
import { useToast } from "src/helpers/useToast";

interface Props {
  label?: string;
  uploadLabel: string;
  onNewImage?: (file: File) => void;
  defaultImageUri?: string;
  allowUpload?: boolean;
}

const MAX_FILE_SIZE = 2 * 10240; // 20MB

export const ImagePicker: FC<Props> = ({ label, allowUpload, defaultImageUri, uploadLabel, onNewImage }) => {
  const inputEl = useRef(null);
  const imageEl = useRef(null);
  const toast = useToast();
  const eventId = useEventId();
  const { t } = useTranslation("upload");

  const [showPreview, setShowPreview] = useState(false);

  const handleInput = async (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = ((window as any).file = files[0]);
      if (file.size / 1024 > MAX_FILE_SIZE) {
        toast(t("fileTooLarge"), "🤷‍♂️", "error");
        console.error(`File too large. Compressed Image. Event: ${eventId}`);
        return;
      }

      try {
        const converted = await compressImage(file);
        const newFile = converted as File;
        setShowPreview(true);

        const url = window.URL.createObjectURL(newFile);
        imageEl.current.src = url;
        if (onNewImage) onNewImage(file);

        const fileReader = new FileReader();
        fileReader.onload = function (fileReaderEvent) {
          (inputEl.current as any).src = fileReaderEvent.target.result;
        };
        fileReader.readAsDataURL(newFile);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="my-8 text-center">
        {label ? <h2 className="mb-2 mb-4">{label}</h2> : null}
        {defaultImageUri && !showPreview && (
          <div className="mb-8 flex flex-col items-center justify-center">
            <div className="h-64 w-64">
              <FirebaseCachedImage uri={defaultImageUri} alt="" />
            </div>
          </div>
        )}
        <div className="flex w-full items-center justify-center">
          <div className="flex flex-col">
            <label
              htmlFor="dropzone-file"
              className="flex h-48 w-48 cursor-pointer flex-col items-center justify-center rounded-2xl border border-dashed border-secondary bg-base-200"
            >
              <div className={`flex justify-center ${showPreview ? "" : "hidden"}`}>
                <img ref={imageEl} className="h-48 w-48 rounded-2xl border border-dashed object-cover" />
              </div>
              <div className={`flex flex-col items-center justify-center pt-5 pb-6 ${showPreview ? "hidden" : ""}`}>
                <UploadIcon className="mb-3 h-10 w-10" strokeWidth={1} />
                <p className="mb-2 text-sm">{uploadLabel}</p>
              </div>
              <input
                onChange={handleInput}
                id="dropzone-file"
                ref={inputEl}
                type="file"
                accept="image/*"
                capture={allowUpload ? undefined : true}
                className="hidden"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
