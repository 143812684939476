import { Footer } from "@components/Footer";
import { HighscoreTable } from "@components/HighscoreTable";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "@helpers/useTranslation";
import { FC } from "react";

type Props = {
  isAdmin: boolean;
};

export const Highscores: FC<Props> = ({ isAdmin }) => {
  const { t } = useTranslation("highscores");

  return (
    <div className="flex h-screen flex-col ">
      <TopNav showBack isAdmin={isAdmin} />
      <main className="overflow-y-scroll">
        <div className="flex flex-col items-center px-8">
          <h1 className="mb-10 mt-16 text-center text-3xl font-semibold">{t("title")}</h1>
          <div className="mb-32 w-full max-w-xl sm:mr-8">
            <HighscoreTable />
          </div>
        </div>
      </main>
      {!isAdmin && <Footer />}
    </div>
  );
};
