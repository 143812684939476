import { EventInfo } from "@components/EventInfo";
import { DownloadIcon } from "@components/Icons/Download";
import { Modal } from "@components/Modal";
import html2canvas from "html2canvas";
import { FC, forwardRef, useRef, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useEvent, useEventId } from "src/helpers/useEvent";

type Props = {
  show: boolean;
  onCancel: () => void;
};

type ComponentToPrintProps = {
  eventId: string;
  qrDownloading: boolean;
  eventName?: string;
  pin: number;
};

const ComponentToPrint = forwardRef<any, ComponentToPrintProps>(({ eventId, qrDownloading, eventName, pin }, ref) => (
  <div ref={ref} className="top-0 px-16 py-8" style={{ width: qrDownloading ? 1000 : "auto" }}>
    <EventInfo size={qrDownloading ? "xxl" : "lg"} eventId={eventId} eventName={eventName} pin={pin} />
  </div>
));

export const QRModal: FC<Props> = ({ show, onCancel }) => {
  const componentRef = useRef();
  const eventId = useEventId();
  const { t } = useTranslation("admin");
  const [event, loading] = useEvent();
  const [qrDownloading, setQrDownloading] = useState(false);
  if (loading) return null;

  const exportAsImage = async () => {
    try {
      const canvas = await html2canvas(componentRef.current);
      const image = canvas.toDataURL("image/png", 1.0);
      setQrDownloading(false);

      const fakeLink = window.document.createElement("a");

      const fileName = `${eventId}.png`;
      fakeLink.download = fileName;
      fakeLink.href = image;
      document.body.appendChild(fakeLink);
      fakeLink.click();
      document.body.removeChild(fakeLink);
      fakeLink.remove();
    } catch (error) {
      console.error(error);
    } finally {
      setQrDownloading(false);
    }
  };

  return (
    <Modal show={show} onCancel={onCancel}>
      <h3 className="text-lg font-bold">{t("qrCode")}</h3>
      <ComponentToPrint
        qrDownloading={qrDownloading}
        eventId={eventId}
        ref={componentRef}
        eventName={event?.name}
        pin={event.pin}
      />
      <div className="text mb-8 flex flex-row justify-center text-center">{t("explainQrCode")}</div>
      <div className="mb-4 flex flex-col">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
          onClick={() => {
            window.scrollTo(0, 0);
            setQrDownloading(true);
            setTimeout(() => {
              exportAsImage();
              onCancel();
            }, 50);
          }}
        >
          <div className="-ml-6 flex items-center">
            <DownloadIcon className="mr-4 h-6 w-6" />
            {t("download")}
          </div>
        </button>
        <div className="flex justify-center">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 "
            onClick={onCancel}
          >
            {t("close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
