import { UploadIcon } from "@components/Icons/Upload";
import { FC, useRef } from "react";

interface Props {
  label?: string;
  uploadLabel: string;
  onNewImages?: (newImages: File[]) => void;
  uploading?: boolean;
  setUploading?: (uploading: boolean) => void;
  setTotalUploadCount?: (count: number) => void;
}

export const MultiPicker: FC<Props> = ({
  label,
  uploadLabel,
  onNewImages,
  uploading,
  setUploading, // This is a UX-related 'hack', allows us to update parent state instantly. Highlights problematic architecture. TODO: rethink
  setTotalUploadCount, // This is a UX-related 'hack', allows us to update parent state instantly. Highlights problematic architecture. TODO: rethink
}) => {
  const inputEl = useRef(null);

  const handleInput = async (e) => {
    const target = e.target as HTMLInputElement;
    const files = Array.from(target.files);
    setUploading(true);
    setTotalUploadCount(files.length);
    if (files && files.length > 0) {
      if (onNewImages) onNewImages(files);
    }
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="text-center">
        {label ? <h2 className="mb-2 mb-4">{label}</h2> : null}
        <div className="flex items-center justify-center">
          <div className="flex flex-col">
            <label htmlFor="dropzone-file">
              <div
                className={`btn-primary btn mb-2 w-full px-4 ${
                  uploading ? "btn-disabled loading pointer-events-none" : ""
                }`}
              >
                {!uploading && <UploadIcon className="mr-4 h-6 w-6" />}
                {uploadLabel}
              </div>
              <input
                onChange={handleInput}
                id="dropzone-file"
                ref={inputEl}
                disabled={uploading}
                type="file"
                accept="image/*"
                multiple={true}
                className="hidden"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
