import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { BackIcon } from "@components/Icons/Back";
import { ChallengesIcon } from "@components/Icons/Challenges";
import { LogoutIcon } from "@components/Icons/Logout";
import { useBranding } from "@helpers/useBranding";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "./EventPreviewModal";

type Props = {
  isUploader?: boolean;
  backLink?: string;
  showBack?: boolean;
  useAllLocales?: boolean;
  goBack?: () => void;
  currentPage?: Page;
};

export const PreviewTopBar: FC<Props> = ({ showBack, backLink, goBack, currentPage }) => {
  const { t } = useTranslation("admin");
  const { brand } = useBranding();
  const isCamdeed = brand === "camdeed";

  return (
    <div className="preview-top-bar navbar sticky top-0 z-50 flex flex-row items-center justify-between border-b-4 border-secondary bg-secondary px-4 px-2 pt-4 shadow-lg shadow-base-300">
      <div className={`${isCamdeed ? "text-white" : "text-neutral"}`}>
        {showBack || backLink ? (
          <a onClick={goBack} className="link ml-2">
            <div className="flex flex-row font-light">
              <BackIcon />
              {t("back")}
            </div>
          </a>
        ) : (
          <a className="ml-4 text-lg uppercase">{t("demonstration")}</a>
        )}
      </div>
      <div className="flex items-center">
        <div className="dropdown-end dropdown">
          <label tabIndex={0} className="btn-ghost btn-circle avatar btn ">
            <FirebaseCachedImage className="btn-circle btn border-secondary" uri="anonymous.webp" alt="" />
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu rounded-box menu-compact mt-3 w-40 border border-base-300 bg-base-200 p-2 shadow"
          >
            <li>
              <button
                className={`flex flex-row ${
                  currentPage === "challenges" ? "pointer-events-none text-slate-600 text-opacity-30 underline" : ""
                }`}
                disabled={currentPage === "challenges"}
                onClick={goBack}
              >
                <ChallengesIcon />
                <span>{t("challenges")}</span>
              </button>
            </li>

            <li></li>
            <li>
              <button onClick={() => {}} className="flex flex-row">
                <LogoutIcon />
                <span>{t("logout")}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
