import { CloseIcon } from "@components/Icons/Close";
import { MaximiseIcon } from "@components/Icons/Maximise";
import { MinimiseIcon } from "@components/Icons/Minimise";
import { encodePin } from "@helpers/codec";
import { FC, useState } from "react";
import QRCode from "react-qr-code";
import { useBranding } from "src/helpers/useBranding";

type Props = {
  title: string;
  pin: number;
  eventId: string;
  open: boolean;
  onClose?: () => void;
};

export const DraggableEventInfo: FC<Props> = ({ open, onClose, pin, title, eventId }) => {
  const [isDragging, setIsDragging] = useState(false);
  const screenHeight = window?.screen?.height;

  const centerX = 100;
  const centerY = screenHeight / 2 - 230;

  const [position, setPosition] = useState({ x: centerX, y: centerY });
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // For desktop
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // For desktop
  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  // For mobile devices (e.g. iPad)
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.touches[0].clientX - position.x,
      y: e.touches[0].clientY - position.y,
    });
  };

  // For mobile devices (e.g. iPad)
  const handleTouchMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.touches[0].clientX - offset.x,
        y: e.touches[0].clientY - offset.y,
      });
    }
  };

  // For both mobile and desktop
  const endDrag = () => {
    setIsDragging(false);
  };

  const [minimised, setMinimised] = useState(false);
  const toggleMinimised = () => setMinimised(!minimised);

  const { appDomain } = useBranding();
  const pinEncoded = encodePin(pin);

  if (!open) return null;

  return (
    <div className="hidden md:block">
      <div
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          cursor: isDragging ? "grabbing" : "grab",
        }}
        className="absolute z-50"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={endDrag}
        onMouseLeave={endDrag} // To handle the case when the mouse leaves the element while dragging
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={endDrag}
      >
        <div className={`hidden border bg-white bg-opacity-90 px-4 py-2 md:block ${minimised ? "rounded-lg" : ""}`}>
          <div className="flex justify-between">
            <button className="text-2xl font-bold text-neutral" onClick={toggleMinimised}>
              {minimised ? <MaximiseIcon /> : <MinimiseIcon />}
            </button>
            {minimised ? null : (
              <button className="text-2xl font-bold text-neutral" onClick={() => onClose && onClose()}>
                <CloseIcon />
              </button>
            )}
          </div>
          {minimised ? null : (
            <div className="py-2 px-4">
              <h3 className="w-52 text-center text-lg font-black">{title}</h3>
              <QRCode
                style={{ height: "auto" }}
                size={208}
                value={`https://${appDomain}/${eventId}?p=${pinEncoded}`}
                className="mt-4 border-8 border-white text-neutral"
                viewBox={`0 0 208 208`}
              />
              <h4 className="mt-4 text-center text-xl font-extrabold">PIN: {pin}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
