import { FC, ReactNode, useContext, useEffect } from "react";
import { AddEvent } from "./pages/addevent";
import { Admin } from "./pages/admin";
import { Challenge } from "./pages/challenge";
import { ChallengeQuestion } from "./pages/challenge/quiz/question";
import { Challenges } from "./pages/challenges";
import { Contact } from "./pages/contact";
import { Customise } from "./pages/customise";
import { ForgotPassword } from "./pages/forgotpassword";
import { Gallery } from "./pages/gallery";
import { Login } from "./pages/login";
import { MyBranding } from "./pages/mybranding";
import { MyEvents } from "./pages/myevents";
import { NewTeam } from "./pages/newteam";
import { NewUser } from "./pages/newuser";
import { PaymentsSuccess } from "./pages/payments";
import { PublicGallery } from "./pages/publicgallery";
import { Register } from "./pages/register";
import { Select } from "./pages/select";
import { SelectTeam } from "./pages/selectteam";
import { Slideshow } from "./pages/slideshow";
import { Teams } from "./pages/teams";
import { TeamSettings } from "./pages/teamsettings";
import { Upload } from "./pages/upload";
import { Uploader } from "./pages/uploader";
import { Highscores } from "./pages/highscores";
import { Intro } from "./pages/intro";
import { Landing } from "./pages/landing";
import { ManageTeams } from "./pages/manageteams";
import { Navigate, Route, Routes } from "react-router-dom";
import { UpgradeNow } from "./components/UpgradeNow/UpgradeNow";
import { useEventId } from "./helpers/useEvent";
import { useToast } from "./helpers/useToast";
import { useTranslation } from "react-i18next";
import { useClaims } from "@helpers/useClaims";
import { EditChallenges } from "@pages/editchallenges";
import { FirebaseContext } from "@helpers/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { usePersistedState } from "@helpers/usePersistedState";
import { Report } from "@pages/report";
import { useBranding } from "@helpers/useBranding";

const LoginRedirect = () => {
  return <Navigate to={`/login`} />;
};

const LandingRedirect = () => {
  const { homepage } = useBranding();
  window.location.href = homepage;
  return null;
};

const Fallback = () => {
  console.log("Fallback");
  return null;
};

type GuardProps = {
  children: ReactNode;
  claims: Claims;
};

const LandingGuard: FC<GuardProps> = ({ children, claims }) => {
  const eventIdFallback = useEventId();
  const eventId = claims?.eventId ?? eventIdFallback;

  const go = (path: string) => {
    if (eventId) return <Navigate to={`/${eventId}${path}`} />;
  };

  const validEventIds = claims ? claims.events || [claims.eventId] : [];
  const correctEventId = validEventIds.includes(eventId);

  const isUser = claims?.role === "user";
  if (isUser && correctEventId) return go("/challenges");

  const isUploader = claims?.role === "uploader";
  if (isUploader && correctEventId) return go("/uploader");

  const isAdmin = claims?.role === "admin";
  if (isAdmin) return go("/admin");

  return <>{children}</>;
};

const NonAdminGuard: FC<GuardProps> = ({ children, claims }) => {
  const eventId = claims?.eventId;
  const go = (path?: string) => {
    if (!path && eventId) return <Navigate to={`/${eventId}`} />;
    if (!path) return <Navigate to="/" />;
    return <Navigate to={path} />;
  };

  const isAlreadyUser = claims?.role === "user";
  if (isAlreadyUser) return go("/challenges");

  const isAdmin = claims?.role === "admin";
  if (isAdmin) return go("/admin");

  const isPublic = claims?.role === "public";
  if (isPublic) return go();

  return <>{children}</>;
};

const UploaderGuard: FC<GuardProps> = ({ children, claims }) => {
  const [appData] = usePersistedState();
  const eventIdFallback = useEventId();
  const eventId = claims?.eventId ?? eventIdFallback;

  const go = (path?: string) => {
    if (!path && eventId) return <Navigate to={`/${eventId}`} />;
    if (!path) return <Navigate to="/" />;
    return <Navigate to={path} />;
  };

  const lostSession = !claims;
  if (lostSession) return go();

  const validEventIds = claims.events || [claims.eventId];
  const isWrongEvent = !validEventIds.includes(eventId);
  if (isWrongEvent) return go();

  const isWrongTeam = appData?.uid !== claims.teamId;
  if (isWrongTeam) return go();

  const isAdmin = claims.role === "admin";
  if (isAdmin) return go("/admin");

  const isUser = claims.role === "user";
  if (isUser) return go("/challenges");

  const isPublic = claims.role === "public";
  if (isPublic) return go();

  return <>{children}</>;
};

const UserGuard: FC<GuardProps> = ({ children, claims }) => {
  const [appData] = usePersistedState();
  const eventIdFallback = useEventId();
  const eventId = claims?.eventId ?? eventIdFallback;

  const go = (path?: string) => {
    if (!path && eventId) return <Navigate to={`/${eventId}`} />;
    if (path) return <Navigate to={path} />;
  };
  const lostSession = !claims;
  if (lostSession) return go();

  const validEventIds = claims.events || [claims.eventId];
  const isWrongEvent = !validEventIds.includes(eventId);
  if (isWrongEvent) return go();

  const isWrongTeam = appData?.uid !== claims.teamId;
  if (isWrongTeam) return go();

  const isAdmin = claims.role === "admin";
  if (isAdmin) return go("/admin");

  const isUploader = claims.role === "uploader";
  if (isUploader) return go("/uploader");

  return <>{children}</>;
};

const LightAdminGuard: FC<GuardProps> = ({ children, claims }) => {
  const go = (path) => {
    return <Navigate to={path} />;
  };

  const lostSession = !claims;
  if (lostSession) return go("/login");

  const isAdmin = claims.role === "admin";
  if (!isAdmin) return go("/login");

  return <>{children}</>;
};

const LoginGuard: FC<GuardProps> = ({ children, claims }) => {
  const eventIdFallback = useEventId();
  const eventId = claims?.eventId ?? eventIdFallback;

  const go = (path) => {
    return <Navigate to={path} />;
  };

  const isAdmin = claims?.role === "admin";
  if (isAdmin) return go(`/${eventId}/admin`);

  return <>{children}</>;
};

const AdminGuard: FC<GuardProps> = ({ children, claims }) => {
  if (!claims)
    return (
      <div className="mt-12 flex flex-col items-center justify-center">
        <div>Your session has timed out</div>
        <div>
          <a className="link mt-6" href="/login">
            Back to Login
          </a>
        </div>
      </div>
    );
  const eventId = useEventId();

  const go = (path) => {
    return <Navigate to={path} />;
  };

  const lostSession = !claims;
  if (lostSession) return go("/login");

  const validEventIds = claims.events || [claims.eventId];
  const wrongEvent = !validEventIds.includes(eventId);
  if (wrongEvent) return go("/login");

  const isAdmin = claims.role === "admin";
  if (!isAdmin) return go("/login");

  return (
    <>
      <UpgradeNow />
      {children}
    </>
  );
};

const SuperuserGuard: FC<GuardProps> = ({ children, claims }) => {
  if (!claims) return null;
  const isAdmin = claims.role === "admin";
  if (!isAdmin) return <Navigate to="/login" />;
  return <>{children}</>;
};

export const AppRoutes: FC = () => {
  const { t } = useTranslation("home");
  const { auth } = useContext(FirebaseContext);
  const [user, userLoading, userError] = useAuthState(auth);
  const [claims, claimsLoading, claimsError] = useClaims(user);
  const toast = useToast();
  const eventId = useEventId();

  useEffect(() => {
    if (userError) {
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      console.error(`Error occured in AppRoutes. EventId: ${eventId}, Error: ${userError}`);
    }
  }, [userError]);

  useEffect(() => {
    if (claimsError) {
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      console.error(`Error occured in AppRoutes. EventId: ${eventId}, Error: ${claimsError}`);
    }
  }, [claimsError]);

  if (userLoading || claimsLoading) return null;

  return (
    <Routes>
      <Route
        path="/report"
        element={
          <SuperuserGuard claims={claims}>
            <Report />
          </SuperuserGuard>
        }
      />
      <Route
        path="/events"
        element={
          <LightAdminGuard claims={claims}>
            <MyEvents />
          </LightAdminGuard>
        }
      />
      <Route
        path="/branding"
        element={
          <LightAdminGuard claims={claims}>
            <MyBranding />
          </LightAdminGuard>
        }
      />
      <Route
        path="/addevent"
        element={
          <LightAdminGuard claims={claims}>
            <AddEvent />
          </LightAdminGuard>
        }
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/admin" element={<Login />} />
      <Route
        path="/login"
        element={
          <LoginGuard claims={claims}>
            <Login />
          </LoginGuard>
        }
      />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/register" element={<Register />} />
      <Route path="/gallery/:shareUuid" element={<PublicGallery />} />

      <Route
        path="/:eventId"
        element={
          <LandingGuard claims={claims}>
            <Landing />
          </LandingGuard>
        }
      />
      <Route
        path="/:eventId/highscores"
        element={
          <UserGuard claims={claims}>
            <Highscores isAdmin={claims?.role === "admin"} />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/intro"
        element={
          <LandingGuard claims={claims}>
            <Intro />
          </LandingGuard>
        }
      />
      <Route
        path="/:eventId/newteam"
        element={
          <NonAdminGuard claims={claims}>
            <NewTeam />
          </NonAdminGuard>
        }
      />
      <Route
        path="/:eventId/newuser"
        element={
          <NonAdminGuard claims={claims}>
            <NewUser />
          </NonAdminGuard>
        }
      />
      <Route
        path="/:eventId/teams"
        element={
          <NonAdminGuard claims={claims}>
            <Teams />
          </NonAdminGuard>
        }
      />

      <Route
        path="/:eventId/admin"
        element={
          <AdminGuard claims={claims}>
            <Admin />
          </AdminGuard>
        }
      />
      <Route
        path="/:eventId/slideshow"
        element={
          <AdminGuard claims={claims}>
            <Slideshow />
          </AdminGuard>
        }
      />
      <Route
        path="/:eventId/manageteams"
        element={
          <AdminGuard claims={claims}>
            <ManageTeams />
          </AdminGuard>
        }
      />
      <Route
        path="/:eventId/gallery"
        element={
          <AdminGuard claims={claims}>
            <Gallery />
          </AdminGuard>
        }
      />
      <Route
        path="/:eventId/customise"
        element={
          <AdminGuard claims={claims}>
            <Customise />
          </AdminGuard>
        }
      />
      <Route path="/:eventId/payments/success" element={<PaymentsSuccess />} />
      <Route
        path="/:eventId/selectteam"
        element={
          <NonAdminGuard claims={claims}>
            <SelectTeam />
          </NonAdminGuard>
        }
      />
      <Route
        path="/:eventId/select"
        element={
          <NonAdminGuard claims={claims}>
            <Select />
          </NonAdminGuard>
        }
      />
      <Route
        path="/:eventId/challenges/:challengeId"
        element={
          <UserGuard claims={claims}>
            <Challenge />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/upload"
        element={
          <UserGuard claims={claims}>
            <Upload />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/uploader"
        element={
          <UploaderGuard claims={claims}>
            <Uploader />
          </UploaderGuard>
        }
      />
      <Route
        path="/:eventId/teamsettings"
        element={
          <UserGuard claims={claims}>
            <TeamSettings />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/challenges/:challengeId/:pid"
        element={
          <UserGuard claims={claims}>
            <ChallengeQuestion />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/challenges"
        element={
          <UserGuard claims={claims}>
            <Challenges />
          </UserGuard>
        }
      />
      <Route
        path="/:eventId/editchallenges"
        element={
          <AdminGuard claims={claims}>
            <EditChallenges />
          </AdminGuard>
        }
      />

      <Route path="/:eventId/login" element={<LoginRedirect />} />
      <Route path="/" element={<LandingRedirect />} />
      <Route path="*" element={<Fallback />} />
    </Routes>
  );
};
