import { Loading } from "@components/Loading";
import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { DoneIcon } from "@components/Icons/Done";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { PreviewTopBar } from "./PreviewTopBar";
import { PreviewFooter } from "./PreviewFooter";

type ChallengeWithCompleted = Challenge & { completed: boolean };

type Props = {
  goToChallenge: (challengeId: string) => void;
  goToHighscores: () => void;
  goToUpload: () => void;
};

export const PreviewChallenges: FC<Props> = ({ goToChallenge, goToHighscores, goToUpload }) => {
  const { t } = useTranslation("challenges");
  const eventId = useEventId();
  const [event] = useEvent();

  const [challenges] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const [fakeSolutions, setFakeSolutions] = useState<Solution[]>([]);

  const [challengesWithSolutions, setChallengesWithSolutions] = useState<ChallengeWithCompleted[]>([]);
  useEffect(() => {
    const challengesWithSolutions = challenges?.map((challenge) => {
      const solution = fakeSolutions?.find((solution) => solution.id === challenge.id);
      return { ...challenge, completed: !!solution?.completed };
    });
    setChallengesWithSolutions(challengesWithSolutions);
  }, [challenges, fakeSolutions]);

  if (!challenges) return null;

  const filterHiddenChallenges = (challenge: Challenge) => !challenge.hidden;
  const orderedChallenges = useMemo(() => {
    if (!event || !challengesWithSolutions) return [];
    let ordered = challengesWithSolutions;
    if (event.order) {
      ordered = event.order.map((id) => challengesWithSolutions?.find((challenge) => challenge.id === id));
    }
    return ordered?.filter((challenge) => !!challenge).filter(filterHiddenChallenges);
  }, [event, challengesWithSolutions]);

  const onChallengeClick = (challenge: Challenge & Solution) => {
    // navigate(`/challenges/${challenge.id}`);
  };

  const { getTranslatedValue } = useTranslatedKey();

  return (
    <div className="iphone-content overflow-y-auto">
      <PreviewTopBar currentPage={"challenges"} />
      <Loading loading={!challenges}>
        <div className="flex justify-center ">
          <h1 className={"mt-16 mb-12 rounded-xl px-4 text-3xl font-bold"}>{t("title")}</h1>
        </div>
        <div className="iphone-content mt-4 mb-8 flex w-full flex-col items-center">
          <div className="w-full px-6">
            {orderedChallenges.map((challenge, index) => {
              const { id, points, completed } = challenge;
              const title = getTranslatedValue(challenge, "title");
              const about = getTranslatedValue(challenge, "about");
              // const handleChallengeClick = () => onChallengeClick(challenge);

              return (
                <div key={id} className="mb-6" role="button">
                  <div className={"rounded-2xl bg-base-200 bg-opacity-90"}>
                    <div
                      className={`card w-full border border-base-300 ${
                        completed ? " bg-success bg-opacity-40" : "bg-base-200"
                      }`}
                    >
                      <div className="tooltip text-left" data-tip={t("previewCopy")}>
                        <div className="flex justify-between">
                          <div className="w-full">
                            <div className="card-body">
                              {completed && (
                                <div className="-m-1 flex justify-center">
                                  <div className="z-100 absolute text-center text-gray-100">
                                    <DoneIcon className="h-16 w-16" />
                                  </div>
                                </div>
                              )}

                              <div className={`${completed ? "blur-xs" : ""}`}>
                                <div className="flex justify-between">
                                  <h2 className="card-title">{title}</h2>
                                  <div className="text-sm font-semibold">{points}</div>
                                </div>
                                <p className="min-h-6">{about}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Loading>
      <PreviewFooter
        goToChallenges={() => {}}
        goToHighscores={goToHighscores}
        goToUpload={goToUpload}
        activePage={"challenges"}
      />
    </div>
  );
};
