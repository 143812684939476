import { useTranslation as use18nTranslation } from "react-i18next";
import { useBranding } from "./useBranding";
import { TFunction } from "i18next";
import { usePersistedState } from "./usePersistedState";
import { useEffect } from "react";

export const useTranslation = (namespace?: string) => {
  const { t, i18n } = use18nTranslation(namespace);
  const { brand, brandName, appDomain } = useBranding();
  const defaultParams = { brand, brandName, appDomain };
  const [appData] = usePersistedState();
  useEffect(() => {
    if (appData.locale) i18n.changeLanguage(appData.locale);
  }, [appData]);

  const customT = (key, params = {}) => {
    return t(key, { ...defaultParams, ...params });
  };

  // Return the custom translation function and i18n instance
  return { t: customT as TFunction, i18n };
};
