import { toast as rhtoast } from "react-hot-toast";

export const dismiss = rhtoast.dismiss;

export const useToast = () => {
  const background = "#333";
  const color = "#fff";

  const getBackground = (type?: string) => {
    switch (type) {
      case "success":
        return "#709e72FA";
      case "error":
        return "#c7352aFA";
      case "loading":
        return "#4bb1b1";
      default:
        return background;
    }
  };

  return (message: string, icon?: string, type = "success", duration = 2500) =>
    rhtoast(message, {
      icon,
      position: "top-center",
      duration,
      style: {
        width: "100%",
        zIndex: 100,
        padding: "16px",
        fontFamily: "inherit",
        fontSize: "1.2rem",
        background: getBackground(type),
        color,
      },
    });
};
