import { useNavigate } from "react-router-dom";

import { FC } from "react";

type Props = {
  children: React.ReactNode;
  href: string;
  active?: boolean;
  disabled?: boolean;
};

export const FooterButton: FC<Props> = ({ children, href, active, disabled, ...props }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`border-t border-base-300 border-opacity-50 bg-base-300 shadow-xl ${
        active ? "bg-opacity-50" : "text-opacity-20"
      } ${disabled ? "disabled cursor-not-allowed" : "cursor-pointer"}}`}
      onClick={() => navigate(href)}
      {...props}
    >
      <button disabled={disabled}>{children}</button>
    </div>
  );
};
