import { useEventId } from "./useEvent";
import { usePersistedState } from "./usePersistedState";
import { useSimpleDocument } from "./useSimpleDocument";

export const useTeam = (): [Team, boolean, Error] => {
  const eventId = useEventId();
  const [appData] = usePersistedState();
  const [team, loading, error] = useSimpleDocument(`events/${eventId}/teams/${appData.uid}`);
  return [team as Team, loading, error];
};

export const useTeamId = (): string | null => {
  const [appData] = usePersistedState();
  return appData.uid;
};
