import { FirebaseCachedImage } from "@components/FirebaseCachedImage";
import { getBeginCopy, getLocaleFlag, useLocales } from "@helpers/locales";
import { useBranding } from "@helpers/useBranding";
import { useEvent, useEventId } from "@helpers/useEvent";
import { useFirebaseCachedImageUrl } from "@helpers/useFirebaseCachedImageUrl";
import { usePersistedState } from "@helpers/usePersistedState";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  onNext: () => void;
};

export const PreviewLanding: FC<Props> = ({ onNext }) => {
  const eventId = useEventId();

  const [event, _loading] = useEvent();
  const { name, type } = event || {};
  let icon = "";
  if (type === "wedding") icon = "💍";
  if (type === "birthday") icon = "🎂";

  const imageUrl = useFirebaseCachedImageUrl(`events/${eventId}/background_light`, `events/${eventId}/background.jpg`);
  const defaultUrl = type === "wedding" ? "/bg-wedding.webp" : "/bg-conference.webp";
  const backgroundImage = imageUrl ? `url(${imageUrl})` : `url(${defaultUrl})`;
  const { i18n } = useTranslation();
  const [appData, setAppData] = usePersistedState();
  const locales = useLocales();
  const hasCustomBranding = event?.hasBranding;
  const { brandName, homepage, logoUrl } = useBranding();

  return (
    <div
      style={{ backgroundImage }}
      className={`iphone-content flex h-max justify-center overflow-hidden bg-gray-800 bg-cover bg-center bg-blend-overlay`}
    >
      <div className="flex justify-center">
        <main className="fadein flex w-full max-w-md flex-1 flex-col items-center justify-between px-4 py-4">
          <div className="mt-16">
            <h1 className="text-center text-4xl font-bold uppercase leading-tight text-white sm:text-5xl">
              <div>{name}</div>
              <span className="text-3xl">{icon}</span>
            </h1>
          </div>
          <div className="flex w-full max-w-xs flex-col justify-center align-bottom">
            {locales.map((locale) => (
              <button
                key={locale}
                className="btn-outline btn mb-7 border border-base-300 bg-base-200 bg-opacity-80 uppercase text-neutral"
                onClick={(e) => {
                  e.preventDefault();
                  setAppData({ ...appData, locale });
                  i18n.changeLanguage(locale);
                  onNext();
                }}
              >
                <span className="-ml-3 mr-4 text-xl">{getLocaleFlag(locale)}</span>
                {getBeginCopy(locale)}
              </button>
            ))}
          </div>
          <div className="bottom-0 max-w-lg text-center">
            <div>
              {hasCustomBranding ? (
                <div className="h-36">
                  <FirebaseCachedImage uri={`events/${eventId}/branding`} alt={brandName} />
                </div>
              ) : (
                <div className="mb-24 h-20 opacity-70">
                  <a href={homepage}>
                    <img src={logoUrl} alt={brandName} className="mx-auto w-24" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
