import { doc as firestoreDoc } from "@firebase/firestore";
import { useContext, useMemo, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "./firebase";

export const useSimpleDocument = <T>(path: string): [T, boolean, Error] => {
  const { firestore } = useContext(FirebaseContext);
  const [snapshot, loading, error] = useDocument(firestoreDoc(firestore, path));

  const [stale, setStale] = useState<T>();

  const fresh = useMemo(() => {
    if (!snapshot) return undefined;
    const doc = snapshot.data();
    const exists = snapshot.exists();
    if (!exists) return undefined;

    const docWithId = { ...doc, id: snapshot.id } as T;
    setStale(docWithId);
    return docWithId;
  }, [snapshot]);

  const data = fresh ?? stale;

  return [data, loading, error];
};
