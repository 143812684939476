import { useLocation } from "react-router-dom";

export const useCurrentPage = () => {
  const location = useLocation();

  // Extract the specific page part from the URL
  const currentPage = location.pathname.split("/").length >= 2 && location.pathname.split("/")[2];
  const root = location.pathname.split("/").length >= 2 && location.pathname.split("/")[1];

  return [currentPage, root];
};
