import { Modal } from "@components/Modal";
import { FC, useEffect, useState } from "react";
import { PreviewLanding } from "./PreviewLanding";
import { useTranslation } from "react-i18next";
import { PreviewIntro } from "./PreviewIntro";
import { PreviewChallenges } from "./PreviewChallenges";
import { PreviewHighscores } from "./PreviewHighscores";
import { PreviewUpload } from "./PreviewUpload";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  show: boolean;
  onClose: () => void;
  className?: string;
};

export type Page =
  | "landing"
  | "intro"
  | "challenges"
  | "photo"
  | "quiz"
  | "video"
  | "greeting"
  | "highscores"
  | "upload";

export const EventPreviewModal: FC<Props> = ({ show, onClose }) => {
  const [page, setPage] = useState<Page>("landing");
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const location = useLocation();
  if (!show) return null;

  useEffect(() => {
    const path = location.pathname;
    navigate(path);

    const handlePopstate = (_event: PopStateEvent) => {
      onClose();
    };
    window.addEventListener("popstate", handlePopstate, false);
    return () => {
      window.removeEventListener("popstate", handlePopstate, false);
    };
  }, []);

  const getPage = () => {
    if (page === "landing") return <PreviewLanding onNext={() => setPage("intro")} />;
    if (page === "intro") return <PreviewIntro onNext={() => setPage("challenges")} />;
    if (page === "challenges") {
      return (
        <PreviewChallenges
          goToChallenge={() => setPage("challenges")}
          goToHighscores={() => setPage("highscores")}
          goToUpload={() => setPage("upload")}
        />
      );
    }
    if (page === "highscores") {
      return <PreviewHighscores goToChallenges={() => setPage("challenges")} goToUpload={() => setPage("upload")} />;
    }
    if (page === "upload") {
      return (
        <PreviewUpload goToChallenges={() => setPage("challenges")} goToHighscores={() => setPage("highscores")} />
      );
    }
    return null;
  };

  return (
    <Modal show={show} onCancel={onClose} size="lg">
      <h3 className="mt-2 text-lg font-bold">{t("eventPreviewModalTitle")}</h3>
      <p className="mt-4 text-center text-sm text-neutral">{t("eventPreviewModalSubtitle")}</p>
      <div className="flex flex-col">
        <div className="iphone-container -m-20">
          <div className="iphone">
            <div className="iphone-speaker" />
            <div className="iphone-camera" />
            <div className="iphone-content">{getPage()}</div>
          </div>
        </div>
        <div className="flex justify-center">
          <button className="btn-primary btn mt-4" onClick={onClose}>
            {t("close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};
