import { FC } from "react";
import { IconProps } from "./IconProps";

export const RightIcon: FC<IconProps> = ({ strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeWidth={strokeWidth ?? 1.5}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M5 12h13M12 5l7 7-7 7" />
  </svg>
);
