import { EventTable } from "@components/Table/EventTable";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { FirebaseContext } from "@helpers/firebase";
import { useContext, useEffect, useState } from "react";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, query, orderBy, limit } from "firebase/firestore";

export const Report = () => {
  const { functions, firestore } = useContext(FirebaseContext);
  const [generateNewReport, generatingNewReport, error] = useHttpsCallable(functions, "generateReport");
  const q = query(collection(firestore, "reports"), orderBy("createdOn", "desc"), limit(1));
  const [snapshot, fetchingReports] = useCollection(q);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  useEffect(() => {
    if (!snapshot) return;
    const hasData = snapshot?.docs.length > 0;
    if (hasData) {
      // get first matching document (we only return a list of 1 always due to 'limit(1)' in query)
      const data = snapshot?.docs[0].data().report;
      setData(data);
    }
  }, [snapshot]);

  return (
    <>
      <OverlaySpinner loading={generatingNewReport || fetchingReports} />
      <EventTable data={data} />
      <div className="mt-4 mb-8 text-center text-sm text-gray-500">
        <h4 className="my-4">Can take up to 10 minutes!</h4>
        <button className="btn" onClick={() => generateNewReport()}>
          Regenerate Report
        </button>
      </div>
    </>
  );
};
