import { Modal } from "@components/Modal";
import { FC } from "react";
import { useTranslation } from "@helpers/useTranslation";

type Props = {
  show: boolean;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<any>;
  onCancel: () => void;
  messageCopy?: string;
  actionCopy?: string;
  type?: "success" | "error";
  className?: string;
};

export const ConfirmModal: FC<Props> = ({ show, onConfirm, onCancel, messageCopy, actionCopy, type }) => {
  const { t } = useTranslation("admin");
  messageCopy = messageCopy || t("thisActionCannotBeUndone");
  actionCopy = actionCopy || t("delete");
  type = type || "error";
  if (!show) return null;
  return (
    <Modal show={show} onCancel={onCancel} className="z-40" size="sm">
      <h3 className="text-lg font-bold">{t("areYouSure")}</h3>
      <p className="py-4">{messageCopy}</p>
      <div className="flex flex-col px-4 py-3">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto"
          onClick={(event) => {
            onConfirm(event);
            onCancel();
          }}
        >
          {actionCopy}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
          onClick={() => onCancel()}
        >
          {t("cancel")}
        </button>
      </div>
    </Modal>
  );
};
