import { FC, RefAttributes } from "react";
import { LinkProps, Link as ReactRouterLink } from "react-router-dom";
import { useEventId } from "src/helpers/useEvent";

export const Link: FC<LinkProps & RefAttributes<HTMLAnchorElement>> = (props) => {
  const eventId = useEventId();
  return (
    <ReactRouterLink {...props} to={`/${eventId}/${props.to}`}>
      {props.children}
    </ReactRouterLink>
  );
};
