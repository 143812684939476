import { FC, useRef, useEffect, useState } from "react";

type Props = {
  src: string;
  className?: string;
  frame?: string; // URL to the image to be used as a preview frame
  autoPlay?: boolean;
  muted?: boolean;
};

export const VideoPlayer: FC<Props> = ({ src, className = "", frame, autoPlay = false, muted = false }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showFrame, setShowFrame] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const hideFrame = () => setShowFrame(false);

      video.addEventListener("play", hideFrame);
      video.addEventListener("pause", hideFrame);

      return () => {
        video.removeEventListener("play", hideFrame);
        video.removeEventListener("pause", hideFrame);
      };
    }
  }, []);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play().catch((error) => {
          console.error("Error trying to play the video:", error);
        });
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <>
      <div className={`relative max-w-full cursor-pointer ${className}`} onClick={togglePlayPause}>
        <div className={`${showFrame && imageLoaded ? "block" : "hidden"}`}>
          <img onLoad={() => setImageLoaded(true)} src={frame} alt="Video preview" className={`w-full`} />
          <div className="absolute top-1/2 left-1/2 flex h-12 w-12 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-black bg-opacity-50">
            <svg viewBox="0 0 24 24" fill="white" width="30" height="30" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" />
            </svg>
          </div>
        </div>
      </div>
      <div
        className={`relative max-w-full cursor-pointer ${className} ${!showFrame ? "visible" : "invisible"}`}
        onClick={togglePlayPause}
      >
        <video
          ref={videoRef}
          preload="auto"
          controls
          className={`w-full`}
          autoPlay={autoPlay}
          muted={muted}
          onClick={(e) => e.stopPropagation()} // Prevent click event from bubbling up to the div
        >
          <source src={src} type="video/webm" />
          <source src={src.replace(".webm", ".mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};
