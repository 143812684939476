import { PlayIcon } from "@components/Icons/Play";
import { UploadIcon } from "@components/Icons/Upload";
import { Link } from "@components/Link";
import { useTranslation } from "@helpers/useTranslation";

export const Select = () => {
  const { t } = useTranslation("select");
  return (
    <div className="flex h-screen flex-col overflow-hidden">
      <div className="flex-1 overflow-y-scroll  p-4">
        <h1 className="mt-16 mb-8 text-center  text-4xl font-semibold">{t("title")}</h1>
        <div className="flex w-full flex-col">
          <div className="self-center">
            <div className="flex w-full max-w-xs flex-1 flex-col justify-between px-4 py-4">
              <div className="mt-16">
                <div className="mb-12 flex w-full flex-col justify-center text-center align-bottom">
                  <h2 className="mt-4 mb-8 text-center text-lg font-normal">{t("about1")}</h2>
                  <Link to="teams">
                    <button className={`btn-primary btn mb-10 w-64 px-4`}>
                      <PlayIcon className="mr-4 h-6 w-6" />
                      {t("play")}
                    </button>
                  </Link>
                  <h2 className="mt-4 mb-8 text-center text-lg font-normal">{t("about2")}</h2>
                  <Link to="newuser">
                    <button className={`btn-outline btn-primary btn mb-4 w-64 px-4`}>
                      <UploadIcon className="mr-4 h-6 w-6" />
                      {t("upload")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
