export const encodeShareData = (data: PublicGalleryURLData) => {
  const dataString = JSON.stringify(data);
  return urlSafeBase64Encode(dataString);
};

export const decodeShareData = (urlSafeString: string): PublicGalleryURLData => {
  const urlDecoded = urlSafeBase64Decode(urlSafeString);
  if (!urlDecoded) return { id: "", n: "", p: "" };
  const data = JSON.parse(urlDecoded);
  return data;
};

export const encodePin = (pin: string | number) => {
  const data: QREventURLData = { pin: `${pin}` };
  const dataString = JSON.stringify(data);
  return urlSafeBase64Encode(dataString);
};

export const decodePin = (urlSafeString: string): string => {
  const urlDecoded = urlSafeBase64Decode(urlSafeString);
  if (!urlDecoded) return "";
  const data: QREventURLData = JSON.parse(urlDecoded);
  return data?.pin;
};

export const urlSafeBase64Encode = (input: string): string => {
  // Encode the string into a Uint8Array using UTF-8 encoding
  const encoder = new TextEncoder();
  const encodedArray = encoder.encode(input);

  // Convert the Uint8Array to a Base64 string
  const base64String = btoa(String.fromCharCode(...Array.from(encodedArray)));

  // Make the Base64 string URL-safe
  const replacedString = base64String.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");

  // remove 'ey' prefix (light obfuscation)
  const finalString = replacedString.slice(2);
  return finalString;
};

export const urlSafeBase64Decode = (input: string): string | undefined => {
  // Add '=' padding if required
  let paddedInput = `ey${input}`;
  while (paddedInput.length % 4 !== 0) {
    paddedInput += "=";
  }

  // Replace URL-safe characters with Base64 equivalents
  const base64 = paddedInput.replace(/-/g, "+").replace(/_/g, "/");

  // Decode from Base64 to a string
  try {
    const decodedArray = atob(base64)
      .split("")
      .map((c) => c.charCodeAt(0));
    const decoder = new TextDecoder();
    // Decode the Uint8Array into a string using UTF-8 encoding
    return decoder.decode(new Uint8Array(decodedArray));
  } catch (e) {
    console.error(e);
    return undefined;
  }
};
