import { ConfirmModal } from "@components/ConfirmModal";
import { DownloadIcon } from "@components/Icons/Download";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";
import { httpsCallable } from "firebase/functions";
import { useContext, useLayoutEffect, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { useParams } from "react-router-dom";
import { decodeShareData } from "src/helpers/codec";
import { downloadAsLink } from "src/helpers/download";
import { FirebaseContext } from "src/helpers/firebase";
import { useBranding } from "src/helpers/useBranding";
import { dismiss, useToast } from "src/helpers/useToast";
import { Pictures } from "@components/Pictures";
import { useSimpleCollection } from "@helpers/useSimpleCollection";
import { Loading } from "@components/Loading";
import { useFirebaseCachedImageUrl } from "@helpers/useFirebaseCachedImageUrl";
import { useSimpleDocument } from "@helpers/useSimpleDocument";

export const PublicGallery = () => {
  const { shareUuid } = useParams();
  const { id: eventId, n: eventName, p: password } = decodeShareData(shareUuid);
  const [loading, setLoading] = useState(false);
  const { functions } = useContext(FirebaseContext);
  const toast = useToast();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [groupDownloadBy, setGroupDownloadBy] = useState<"challenge" | "team">("team");
  const { t } = useTranslation("admin");
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [teams, tLoading] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const [challenges, cLoading] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [event, evLoading] = useSimpleDocument<AppEvent>(`events/${eventId}`);
  const { homepage } = useBranding();

  // If no valid eventId passed, redirect to homepage
  useLayoutEffect(() => {
    if (!eventId) {
      console.error("No valid eventId passed to public gallery");
      window.location.href = homepage;
    }
  }, [eventId]);

  const downloadAll = async () => {
    setLoading(true);
    try {
      toast(t("downloadStarted"), "📸", "success", 60000);
      await download();
    } catch (error) {
      dismiss();
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    }

    setLoading(false);
  };

  const download = async () => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
      const func = httpsCallable(functions, "downloadPhotos", { timeout: 8 * 60 * 1000 });
      const groupBy = groupDownloadBy;
      const result = await func({ eventId, password, shareUuid, groupBy });
      const url = result?.data as string;
      setDownloadUrl(url);

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
      if (isIOS) {
        window.location.assign(url);
        toast(t("allPhotosDownloaded"), "📸", "success");
      } else {
        downloadAsLink(url, `${eventName}-photos-${groupBy}.zip`);
        toast(t("allPhotosDownloaded"), "📸", "success");
      }
    } catch (error) {
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    }
  };

  const { logoUrl } = useBranding();

  const imageUrl = useFirebaseCachedImageUrl(`events/${eventId}/background_light`, `events/${eventId}/background.jpg`);
  const defaultUrl = event?.type === "wedding" ? "/bg-wedding.webp" : "/bg-conference.webp";
  const backgroundImage = evLoading ? "" : imageUrl || defaultUrl;

  return (
    <div className="flex flex-col">
      <OverlaySpinner loading={loading} />
      <div className="navbar top-0 z-10 flex justify-between border-b-4 px-2 shadow-lg shadow-base-300">
        <div className="ml-4">
          <a href={homepage}>
            <img className="color-white h-16" src={logoUrl} />
          </a>
        </div>

        <div className="align-end muted mr-2 flex flex-col text-xs">
          <div>{t("tryApp")}</div>
          <a href={homepage} className="link self-end">
            {t("learnMore")}
          </a>
        </div>
      </div>
      <main className="flex-1 flex-col p-4">
        <>
          <h1 className="mt-6 mb-8 text-center  text-4xl font-semibold">{t("publicGalleryTitle")}</h1>
          <h1 className="mt-4 mb-8 text-center text-xl">{eventName}</h1>
          <ConfirmModal
            type="success"
            messageCopy={`${t("areYouSureDownloadAllPhotos")}`}
            actionCopy={`${t("downloadAllPhotos")}`}
            show={showConfirmModal}
            onCancel={() => setShowConfirmModal(false)}
            onConfirm={downloadAll}
          />

          <div className="flex justify-center">
            <img
              className="fadein mb-8 h-64 w-64 rounded-full border border-4 border-base-300 object-cover"
              src={backgroundImage}
              alt=""
            />
          </div>
          <div className="flex w-full flex-col items-center">
            <div className={`${activeTab === 1 ? "" : "hidden"} w-full max-w-sm px-6`}>
              <div className="mb-6 mt-2 flex w-full flex-col">
                <label className="label">
                  <span className="label-text">{`${t("groupDownloadBy")}`}</span>
                </label>
                <select
                  className="select-bordered select-secondary select"
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setGroupDownloadBy(e.target.value as "challenge" | "team");
                    setDownloadUrl(null);
                  }}
                >
                  <option selected={groupDownloadBy === "team"} value={"team"}>
                    {t("groupByTeam")}
                  </option>
                  <option selected={groupDownloadBy === "challenge"} value={"challenge"}>
                    {t("groupByChallenge")}
                  </option>
                </select>
              </div>
              <>
                {downloadUrl ? (
                  <div className="mb-12 mt-4 flex max-w-lg items-center">
                    <h4> {t("downloadNotOpened")}</h4>
                    <a href={downloadUrl} className="btn-primary btn mt-4 max-w-sm" target="_blank" rel="noreferrer">
                      <DownloadIcon className="mr-2 -mt-1 h-5 w-5" />
                      <span className="font-normal underline">{t("tryDownloadDirectly")}</span>
                    </a>
                  </div>
                ) : (
                  <div className="mb-12 flex justify-center">
                    <button
                      type="button"
                      disabled={loading}
                      className={`btn-primary btn mt-4 ${loading ? "loading" : ""}`}
                      onClick={() => setShowConfirmModal(true)}
                    >
                      <DownloadIcon className="mr-2 -mt-1 h-5 w-5" />
                      <span className="font-normal underline">{t("downloadAllPhotos")}</span>
                    </button>
                  </div>
                )}
              </>
            </div>
            {activeTab === 2 && (
              <Loading loading={tLoading || cLoading}>
                <Pictures
                  eventName={eventName}
                  challenges={challenges}
                  teams={teams}
                  eventId={eventId}
                  allowDownload={true}
                  allowDelete={false}
                />
              </Loading>
            )}
          </div>
        </>
      </main>
    </div>
  );
};
