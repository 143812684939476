export const compressImage = (inputBlob: Blob, maxWidth = 600, maxHeight = 600, quality = 0.5): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectURL = URL.createObjectURL(inputBlob);

    img.onload = () => {
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions while maintaining aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Create a canvas to draw the resized image
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the canvas data to Blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to create blob"));
            }
          },
          "image/jpeg",
          quality,
        ); // Set the image format and quality
      } else {
        reject(new Error("Failed to get canvas context"));
      }

      // Release the object URL to free up resources
      URL.revokeObjectURL(objectURL);
    };

    img.onerror = () => {
      // Release the object URL to free up resources
      URL.revokeObjectURL(objectURL);
      reject(new Error("Failed to load image"));
    };

    img.src = objectURL;
  });
};
