import { SpinnerIcon } from "@components/Icons/Spinner";
import { VideoPlayer } from "@components/VideoPlayer/VideoPlayer";
import { useFirebaseCachedVideoUrl } from "@helpers/useFirebaseCachedVideoUrl";
import { FC } from "react";
import { useFirebaseCachedImageUrl } from "src/helpers/useFirebaseCachedImageUrl";

type Props  = {
  uri: string;
  className?: string;
}

export const FirebaseCachedVideo: FC<Props> = ({ className, uri }) => {
  const videoUrl = useFirebaseCachedVideoUrl(`${uri}.mp4`, true);
  const imageUrl = useFirebaseCachedImageUrl(`${uri}_light`);

  const loadingMedia = !videoUrl || !imageUrl;
  if (loadingMedia)
    return (
      <div className="my-6 flex justify-center">
        <SpinnerIcon className="h-16 w-16 max-w-sm" />
      </div>
    );
  return <VideoPlayer frame={imageUrl} className={className} src={videoUrl} />;
};
