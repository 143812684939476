import { ConfirmModal } from "@components/ConfirmModal";
import { ClipboardIcon } from "@components/Icons/Clipboard";
import { Loading } from "@components/Loading";
import { Modal } from "@components/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { encodeShareData } from "src/helpers/codec";
import { FirebaseContext } from "src/helpers/firebase";
import { uid } from "src/helpers/uid";
import { useBranding } from "src/helpers/useBranding";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useToast } from "src/helpers/useToast";

type Props = {
  show: boolean;
  onClose: () => void;
  className?: string;
};

export const ShareModal: FC<Props> = ({ show, onClose }) => {
  const { t } = useTranslation("admin");
  const [event, eLoading] = useEvent();
  const eventId = useEventId();

  const { functions, firestore } = useContext(FirebaseContext);
  const toast = useToast();
  const [generating, setGenerating] = useState(false);

  const [shareUuid, setShareUuid] = useState<string | undefined>("");
  const [shareUrl, setShareUrl] = useState<string | undefined>("");
  const [createShareLink] = useHttpsCallable(functions, "createShareLink");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (event?.shareUuid) setShareUuid(event?.shareUuid);
  }, [show, event]);

  const onSubmit = async () => {
    setGenerating(true);

    const previewPassword = uid();
    const shareUuid = encodeShareData({ p: previewPassword, id: eventId, n: event.name });
    setShareUuid(shareUuid);

    const result = await createShareLink({ eventId, previewPassword, shareUuid: shareUuid });
    const success = (result.data as any).success;
    if (!success) {
      toast(t("errorGeneratingShareLink"), "🤷‍♂️", "error");
      setGenerating(false);
      return;
    }

    setGenerating(false);
    toast(t("previewPasswordUpdated"), "✅");
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    toast(t("copiedToClipboard"), "📋");
  };

  const resetLink = async () => {
    const docRef = doc(firestore, `events/${eventId}`);
    await updateDoc(docRef, { shareUuid: "" });
    setShareUuid("");
    setShareUrl("");
    toast(t("shareLinkReset"), "✅", "success", 4000);
  };

  const { appDomain } = useBranding();
  useEffect(() => {
    if (shareUuid) {
      const shareUrl = `${appDomain}/gallery/${shareUuid}`;
      setShareUrl(shareUrl);
    }
  }, [shareUuid]);

  if (!show) return null;
  return (
    <main>
      <ConfirmModal
        messageCopy={`${t("oldLinkWillStopWorking")}`}
        actionCopy={`${t("invalidateOld")}`}
        show={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onConfirm={resetLink}
      />
      <Modal show={show} onCancel={onClose}>
        <Loading loading={eLoading}>
          <h3 className="text-lg font-bold">{t("shareModalTitle")}</h3>
          <p className="whitespace-pre-wrap pt-8">{t("shareModalAbout")}</p>
          <div className="flex flex-col">
            {event && !event.shareUuid && (
              <div className="mx-12 mt-8 mb-2 flex flex-col justify-center">
                <button
                  onClick={onSubmit}
                  disabled={generating}
                  className={`${generating ? "loading" : ""} btn-primary btn mx-0.5`}
                >
                  {t("generate")}
                </button>
              </div>
            )}

            {event && event.shareUuid && (
              <>
                <div className="mt-8 flex w-full">
                  <div className="w-full">
                    <label className="label text-sm">{t("shareModalLink")}</label>
                    <input
                      readOnly
                      className={`input-bordered input w-full bg-slate-100 dark:text-slate-500`}
                      value={shareUrl}
                    />
                  </div>

                  <button type="button" className="btn-ghost btn ml-2 self-end" onClick={copyToClipboard}>
                    <ClipboardIcon />
                  </button>
                </div>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="btn-link btn mt-2 text-center text-xs text-neutral"
                    onClick={() => setShowConfirmModal(true)}
                  >
                    {t("invalidateOld")}
                  </button>
                </div>
              </>
            )}
            <div className="mt-6 flex justify-center">
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
                onClick={onClose}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </Loading>
      </Modal>
    </main>
  );
};
