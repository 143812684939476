import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { doc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { useTeamId } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";
import { CreateModal } from "./CreateModal";
import { EditModal } from "./EditModal";
import { DoneIcon } from "@components/Icons/Done";
import { usePersistedState } from "src/helpers/usePersistedState";
import { PremiumModal } from "@components/PremiumModal/PremiumModal";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { reorder } from "@helpers/reorder";

const getItemStyle = (_isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
});

export const EditChallenges: FC = () => {
  const { t } = useTranslation("challenges");

  const eventId = useEventId();
  const [event] = useEvent();
  const teamId = useTeamId();

  const [challenges] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const [solutions] = useSimpleCollection<Solution>(`events/${eventId}/teams/${teamId}/solutions`);

  const [activeChallenge, setActiveChallenge] = useState<(Challenge & Solution) | undefined>(undefined);
  const [newChallenge, setNewChallenge] = useState(false);

  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const challengesWithSolutions = useMemo(
    () =>
      challenges?.map((challenge) => {
        const solution = solutions?.find((solution) => solution.id === challenge.id);
        return { ...challenge, completed: !!solution?.completed };
      }),
    [challenges, solutions],
  );

  const orderedChallenges = useMemo(() => {
    if (!event || !challengesWithSolutions) return [];
    let ordered = challengesWithSolutions;
    if (event.order) {
      ordered = event.order.map((id) => challengesWithSolutions?.find((challenge) => challenge.id === id));

      // also find any that are not in the order and add them to the end
      const notOrdered = challengesWithSolutions?.filter((challenge) => !event.order.includes(challenge.id));
      ordered = [...ordered, ...notOrdered];
    }
    return ordered?.filter((challenge) => !!challenge);
  }, [event, challengesWithSolutions]);

  const onChallengeClick = (challenge: Challenge & Solution) => setActiveChallenge(challenge);

  const onNewChallengeClick = () => {
    setNewChallenge(true);
  };

  const toast = useToast();
  const { firestore } = useContext(FirebaseContext);
  const onDragEnd = async (result) => {
    if (!result.destination) return;
    let order = event?.order;

    // if no previous order exists (can happen if user starts without pre-generated challenges)
    // or if the order length is different from the current challenges length
    // then we create a new order based on the current (default) order
    if (!order || order.length !== challenges?.length) {
      order = challenges?.map((challenge) => challenge.id);
    }

    const newOrder = reorder(order, result.source.index, result.destination.index);

    const docRef = doc(firestore, `events/${eventId}`);
    await updateDoc(docRef, { order: newOrder });
    toast(t("updated"), "👍", "success", 1000);
  };

  const { getTranslatedValue } = useTranslatedKey();

  const canAddMore = !!event?.isPremium || challenges?.length < 5;
  const isTrial = !!event?.isTrial;

  if (!challenges || !solutions) return null;

  return (
    <div className="h-screen">
      <main>
        <TopNav isAdmin={true} showBack={true} backLink="/admin" />
        {showPremiumModal && <PremiumModal show={showPremiumModal} onClose={() => setShowPremiumModal(false)} />}
        {!!activeChallenge && <EditModal challenge={activeChallenge} close={() => setActiveChallenge(undefined)} />}
        {!!newChallenge && <CreateModal close={() => setNewChallenge(false)} />}
        <Loading loading={!challenges || !solutions}>
          <div className="flex justify-center ">
            <h1 className={"mt-16 mb-12 rounded-xl px-4 text-3xl font-bold"}>{t("title")}</h1>
          </div>
          <div className="mt-4 mb-8 flex w-full flex-col items-center">
            <div className="w-full px-6 md:px-32 xl:px-64 xl:px-96">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {orderedChallenges.map((challenge, index) => {
                        const { id, points, completed, hidden } = challenge;
                        const title = getTranslatedValue(challenge, "title");
                        const about = getTranslatedValue(challenge, "about");

                        const trialDegrade = isTrial && index > 2; // blur all but the first few challenges if it's a trial event
                        const handleChallengeClick = () => {
                          if (trialDegrade) return setShowPremiumModal(true);
                          return onChallengeClick(challenge);
                        };
                        return (
                          <Draggable key={id} draggableId={id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <div key={id} className="mb-6" role="button" onClick={handleChallengeClick}>
                                  <div className={"rounded-2xl bg-base-200 bg-opacity-90"}>
                                    {trialDegrade && (
                                      <div className="flex justify-center">
                                        <div className="z-100 absolute mt-12 text-center ">
                                          <span className="p-4 text-center font-black text-black">
                                            {t("upgradeToUnlock")}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      className={`card w-full border border-base-300 ${
                                        completed ? " bg-success bg-opacity-40" : "bg-base-200"
                                      } ${trialDegrade ? "bg-opacity-40 blur" : ""} ${
                                        snapshot.isDragging ? "border-2 border-primary" : ""
                                      } shadow-xl
                                      ${hidden ? "bg-slate-400" : "bg-base-200"} 
                                      `}
                                    >
                                      <div className="flex justify-between">
                                        <div className="w-full">
                                          <div className="card-body">
                                            {completed && (
                                              <div className="flex justify-center">
                                                <div className="z-100 absolute text-center text-gray-100">
                                                  <DoneIcon className="h-16 w-16" />
                                                </div>
                                              </div>
                                            )}

                                            <div className={`${completed || trialDegrade ? "blur-xs" : ""}`}>
                                              <div className="flex justify-between">
                                                <h2 className="card-title">{title}</h2>
                                                <div className="text-sm font-semibold">{points}</div>
                                              </div>
                                              <p>{about}</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={`${!trialDegrade ? "flex" : "hidden"}`}>
                                          <div
                                            className={`flex items-center rounded-2xl text-4xl
                                            ${hidden ? "bg-slate-400" : "bg-gradient-to-r from-base-200 to-base-300"}  
                                            `}
                                            {...provided.dragHandleProps}
                                          >
                                            <div className="mx-4">⋮</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="mb-8 flex justify-center">
                <button
                  onClick={() => (canAddMore ? onNewChallengeClick() : setShowPremiumModal(true))}
                  className={`btn-outline btn-primary btn my-6 h-10 w-28 text-lg ${canAddMore ? "" : "btn-inactive"}`}
                  type="button"
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </Loading>
      </main>
    </div>
  );
};
