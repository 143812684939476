import { FC } from "react";
import QRCode from "react-qr-code";
import { encodePin } from "src/helpers/codec";
import { useBranding } from "src/helpers/useBranding";

type Props = {
  eventName?: string;
  pin: number;
  eventId: string;
  size?: "sm" | "lg" | "xl" | "xxl";
};

export const EventInfo: FC<Props> = ({ eventName, pin, size, eventId }) => {
  let qrSize = 256;
  if (size === "lg") qrSize = 312;
  if (size === "xl") qrSize = 512;
  if (size === "xxl") qrSize = 2048;

  let pinCopyStyle = "text-lg mb-2";
  if (size === "lg") pinCopyStyle = "text-2xl mb-2";
  if (size === "xl") pinCopyStyle = "text-3xl mb-2";
  if (size === "xxl") pinCopyStyle = "text-7xl mb-12";

  let appUrlSize = "text-sm";
  if (size === "lg") appUrlSize = "text-base";
  if (size === "xl") appUrlSize = "text-lg";
  if (size === "xxl") appUrlSize = "text-5xl";

  let headingStyle = "text-2xl mb-6";
  if (size === "lg") headingStyle = "text-3xl mb-6";
  if (size === "xl") headingStyle = "text-4xl mb-6";
  if (size === "xxl") headingStyle = "text-8xl mb-24";

  const pinEncoded = encodePin(pin);

  const { appDomain } = useBranding();
  return (
    <div>
      <div className="mb-4 text-center">
        <h1 className={`${headingStyle} font-bold`}>{eventName || ""}</h1>
        <h4 className={`${pinCopyStyle} font-extrabold`}>PIN: {pin}</h4>
      </div>

      <div className="flex flex-col items-center">
        <QRCode
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          size={qrSize}
          value={`https://${appDomain}/${eventId}?p=${pinEncoded}`}
          viewBox={`0 0 ${qrSize} ${qrSize}`}
        />
        <h4
          className={`pt-2 pb-4 text-center ${appUrlSize} font-semibold text-neutral`}
        >{`${appDomain}/${eventId}`}</h4>
      </div>
    </div>
  );
};
