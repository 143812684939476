import { Footer } from "@components/Footer";
import { Input } from "@components/Input";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { doc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useTeam } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";

export const TeamSettings: FC = () => {
  const { t } = useTranslation("teamsettings");
  const { firestore } = useContext(FirebaseContext);

  const [team, loading] = useTeam();
  const [event, eventLoading] = useEvent();
  const [updating, setUpdating] = useState(false);
  const eventId = useEventId();
  const toast = useToast();

  const isPromoEvent = event?.isPromo ?? false;

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      contact: true,
    },
  });

  useEffect(() => {
    if (team) {
      const { name, email, password, contact } = team;
      reset({ name, email, password, contact });
    }
  }, [team]);

  const onSubmit = async ({ name, email, password, contact }) => {
    try {
      setUpdating(true);
      const docRef = doc(firestore, `events/${eventId}/teams/${team.id}`);
      await updateDoc(docRef, { name, email, password, contact });
      setUpdating(false);
      toast(t("teamSettingsUpdated"), "✅");
    } catch (error) {
      console.error(`Problem occured updating team ${team.id} in event ${eventId}, error: ${error}`);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  };

  return (
    <div className="h-screen">
      <main>
        <TopNav showBack />
        <Loading loading={!team || !event || loading || eventLoading}>
          <h1 className="mt-16 mb-8 w-full text-center  text-4xl font-bold">{t("title")}</h1>
          <div className="px-8">
            <form onSubmit={handleSubmit(onSubmit)} className="h-100 flex w-full flex-col justify-center">
              <div className="align-center mx-auto mb-4 flex w-full max-w-sm">
                <Input
                  label={t("teamName")}
                  name="name"
                  type="name"
                  disabled
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  autoComplete="on"
                  tooltip={t("teamNameTooltip")}
                />
              </div>
              {isPromoEvent && (
                <div className="align-center mx-auto mb-4 flex w-full max-w-sm">
                  <Input
                    label={t("email")}
                    name="email"
                    required
                    placeholder={t("emailExample")}
                    type="email"
                    requiredMessage={t("fieldRequired")}
                    errors={errors}
                    register={register}
                    autoComplete="on"
                  />
                </div>
              )}

              <div className="align-center mx-auto mb-12 flex w-full max-w-sm">
                <Input
                  label={t("password")}
                  name="password"
                  type="password"
                  placeholder={t("passwordPlaceholder")}
                  requiredMessage={t("fieldRequired")}
                  errors={errors}
                  register={register}
                  autoComplete="on"
                  tooltip={t("passwordTooltip")}
                />
              </div>
              <div className="align-center mx-auto mb-12 flex w-full max-w-sm justify-center">
                <button
                  type="submit"
                  disabled={loading || updating}
                  className={`${loading ? "loading" : ""} btn-primary btn mb-4 max-w-sm`}
                >
                  {t("update")}
                </button>
              </div>
            </form>
          </div>
        </Loading>
      </main>
      <Footer />
    </div>
  );
};
