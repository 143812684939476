import { ref } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import { FC, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";

interface Props {
  uri: string;
  defaultUri?: string;
  alt?: string;
  className?: string;
}

export const FirebaseFreshImage: FC<Props> = ({ className, uri, alt }) => {
  const { storage } = useContext(FirebaseContext);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const downloadRef = ref(storage, uri);
    getDownloadURL(downloadRef)
      .then((url) => setImageUrl(url))
      .catch((error) => {
        const notFound = error.code === "storage/object-not-found";
        if (notFound) return;

        console.error(error);
      });
  }, []);

  if (!imageUrl) return <></>;
  return <img className={className} src={imageUrl} alt={alt} />;
};
