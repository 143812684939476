import { FirebaseCachedDeleteImage } from "@components/FirebaseCachedImage/FirebaseCachedDeleteImage";
import { Footer } from "@components/Footer";
import { BackIcon } from "@components/Icons/Back";
import { MultiPicker } from "@components/ImagePicker";
import { Link } from "@components/Link";
import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { useTranslation } from "@helpers/useTranslation";
import { Timestamp, getDoc, deleteDoc, deleteField, doc, updateDoc, writeBatch } from "firebase/firestore";
import { ref as storageRef, uploadBytes } from "firebase/storage";
import { useContext, useMemo, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { uid } from "src/helpers/uid";
import { useEventId } from "src/helpers/useEvent";
import { useTeam } from "src/helpers/useTeam";
import { useToast } from "src/helpers/useToast";

const ALLOWED_MAX_EXTRA_IMAGES = 500;
const MAX_FILE_SIZE = 2 * 10240; // 20MB

export const Upload = () => {
  const { t } = useTranslation("upload");
  const { storage, firestore } = useContext(FirebaseContext);
  const [team] = useTeam();
  const [uploading, setUploading] = useState(false);
  const eventId = useEventId();
  const toast = useToast();
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalUploadCount, setTotalUploadCount] = useState(0);

  const sortByFirebaseTimestamp = (a: string, b: string) => {
    const aTimestamp = team?.extrasMap[a];
    const bTimestamp = team?.extrasMap[b];
    if (!aTimestamp || !bTimestamp) return 0;
    return bTimestamp.seconds - aTimestamp.seconds;
  };

  const extras = useMemo(() => Object.keys(team?.extrasMap || {}).sort(sortByFirebaseTimestamp), [team?.extrasMap]);
  const numberOfExtraPics = extras.length;

  const upload = async (newImages: File[]) => {
    setUploading(true);

    if (newImages.length > ALLOWED_MAX_EXTRA_IMAGES) {
      toast(`${t("tooManyImages")}${ALLOWED_MAX_EXTRA_IMAGES}`, "🤷‍♂️", "error");
      console.error(`Too many images. Event: ${eventId}, Team: ${team?.id}`);
      newImages = newImages.slice(0, ALLOWED_MAX_EXTRA_IMAGES); // only upload the first 100 images
    }

    try {
      if (!team) throw new Error(`Team not found. Event: ${eventId}, Team: ${team?.id}`);

      setTotalUploadCount(newImages.length);
      const batch = writeBatch(firestore); // Reduce the number of writes to Firestore

      const extrasIds = [];
      for (const newImage of newImages) {
        if (newImage.size / 1024 > MAX_FILE_SIZE) {
          toast(t("fileTooLarge"), "🤷‍♂️", "error");
          console.error(`File too large. Compressed Image. Event: ${eventId}, Team: ${team.id}`);
          continue; // skip this image
        }

        const id = uid();
        const imagePath = `events/${eventId}/teams/${team.id}/extras/${id}`;
        const imageRef = storageRef(storage, imagePath);

        try {
          await uploadBytes(imageRef, newImage);
        } catch (error) {
          console.error(error);
          continue;
        }

        setUploadedCount((prev) => prev + 1);

        extrasIds.push(id);

        // Keep track of extas in a separate collection (future proofing)
        const extrasDocRef = doc(firestore, `events/${eventId}/teams/${team.id}/extras/${id}`);
        batch.set(extrasDocRef, { createdOn: Timestamp.now() });
      }

      // Get extras map and add the new ids to it
      try {
        const teamDocRef = doc(firestore, `events/${eventId}/teams/${team.id}`);
        const teamDoc = await getDoc(teamDocRef);
        const teamData = teamDoc.data();
        const extrasMap = teamData?.extrasMap || {};

        // add new extra IDs to the map
        for (const id of extrasIds) {
          if (extrasMap[id]) continue;
          extrasMap[id] = Timestamp.now();
        }
        await updateDoc(teamDocRef, { extrasMap });
      } catch (error) {
        console.error("!WORK REQUIRED! Batch update failed and will need to be retried manually.");
        console.error(error);
        if (error.message.includes("PERMISSION_DENIED")) toast(t("sessionExpired"), "🤷‍♂️", "error");
        else toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      }

      // Run the future proof updates
      batch
        .commit()
        .then(() => {
          toast(t("uploaded"), "✅");
        })
        .catch((error) => {
          console.error("!WORK REQUIRED! Batch update failed and will need to be retried manually.");
          console.error(error.message);
          if (error.message.includes("PERMISSION_DENIED")) toast(t("sessionExpired"), "🤷‍♂️", "error");
          else toast(t("somethingWentWrong"), "🤷‍♂️", "error");
        });
    } catch (error) {
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  const deletePhoto = async (imageId: string) => {
    try {
      // Remove from map field
      const teamDocRef = doc(firestore, `events/${eventId}/teams/${team.id}`);
      await updateDoc(teamDocRef, {
        [`extrasMap.${imageId}`]: deleteField(),
      });

      // Remove from collection (future proofing)
      const docRef = doc(firestore, `events/${eventId}/teams/${team.id}/extras/${imageId}`);
      await deleteDoc(docRef);
    } catch (error) {
      console.error(error);
    }
    toast(t("deleted"), "✏️");
  };

  // TODO: change after mokyklai80
  // const isPromoEvent = event?.isPromo;
  const isPromoEvent = false;

  const showUpload = numberOfExtraPics < ALLOWED_MAX_EXTRA_IMAGES;
  return (
    <div className="flex flex-col">
      <TopNav showBack />
      <main className="flex-1 flex-col">
        <div className="px-8">
          <h1 className="mt-12 mb-8 text-center text-3xl font-semibold">{t("title")}</h1>
          {!isPromoEvent && <p className="mb-8 text-center">{t("about")}</p>}

          {!isPromoEvent && showUpload && (
            <MultiPicker
              uploading={uploading}
              uploadLabel={t("uploadButton")}
              onNewImages={upload}
              setUploading={setUploading}
              setTotalUploadCount={setTotalUploadCount}
            />
          )}
          {uploading && (
            <p className="mb-12 text-center text-sm font-bold">
              {`${t("uploading")}... ${uploadedCount} / ${totalUploadCount}`}
            </p>
          )}
          {isPromoEvent && (
            <>
              <p className="mx-8 mb-12 mt-12 whitespace-pre-wrap pt-8 text-center  text-lg">{t("promoEvent")}</p>
              <div className="flex justify-center">
                <Link to={`challenges`} className="btn-ghost btn">
                  <BackIcon className="mr-1 h-6 w-6" />
                  {t("goBackToChallenges")}
                </Link>
              </div>
            </>
          )}

          <Loading loading={!team}>
            <div className="mt-12 grid grid-cols-2 gap-4 md:grid-cols-4">
              {extras.map((id) => (
                <FirebaseCachedDeleteImage
                  key={id}
                  extraId={id}
                  teamId={team.id}
                  eventId={eventId}
                  alt=""
                  onDelete={deletePhoto}
                />
              ))}
            </div>
          </Loading>
        </div>
      </main>
      <Footer />
    </div>
  );
};
