export const downloadAsLink = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.target = "_blank"; // Open link in a new tab
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadAsBlob = async (url: string, filename: string) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const url = reader.result as string;
      downloadAsLink(url, filename);
    };
  } catch (e) {
    console.error("Error downloading the image:", e);
  }
};

export const downloadZIPAsLink = (url: string, filename: string) => {
  // Fetch the file data using a GET request
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create an object URL for the blob object
      const blobUrl = window.URL.createObjectURL(blob);

      // Create a new anchor element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;

      // Append the link to the body, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the object URL
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => console.error("Error downloading file:", error));
};
