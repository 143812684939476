import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import ReactDOM from "react-dom/client";
import App from "./App";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.Replay(),
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // (capture 100% of the transactions in dev), 10% in production
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
