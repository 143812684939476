import { getDownloadURL, ref } from "firebase/storage";
import { useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";

export const useFirebaseCachedImageUrl = (uri, defaultUri = "", secondDefaultUri = "", r = 1) => {
  const cache = useContext(ImageCacheContext);
  const { storage } = useContext(FirebaseContext);
  const [imageUrl, setImageUrl] = useState<string>(cache.current.get(uri) ?? "");
  const retries = useRef(r);

  const handleDefault = async () => {
    const defaultUrl = cache.current.get(defaultUri);
    if (defaultUrl) {
      setImageUrl(defaultUrl);
    } else {
      try {
        const downloadRef = ref(storage, defaultUri);
        const url = await getDownloadURL(downloadRef);
        cache.current.set(uri, url);
        setImageUrl(url);
      } catch (error) {
        const notFound = error.code === "storage/object-not-found";
        if (notFound && secondDefaultUri) await handleSecondDefault();

        // Log error to Sentry, but only if it's not a 404 (handled case)
        const found = !notFound;
        if (found) console.error(error);
      }
    }
  };

  const handleSecondDefault = async () => {
    const secondDefaultUrl = cache.current.get(secondDefaultUri);
    if (secondDefaultUrl) {
      setImageUrl(secondDefaultUrl);
    } else {
      const downloadRef = ref(storage, secondDefaultUri);
      const url = await getDownloadURL(downloadRef);
      cache.current.set(uri, url);
      setImageUrl(url);
    }
  };

  const downloadImage = async (uri: string) => {
    try {
      const downloadRef = ref(storage, uri);
      const url = await getDownloadURL(downloadRef);
      cache.current.set(uri, url);
      setImageUrl(url);
    } catch (error) {
      const notFound = error.code === "storage/object-not-found";
      if (notFound && defaultUri) await handleDefault();

      // Try several times to force refresh the image
      if (retries.current > 0) {
        retries.current -= 1;
        setTimeout(() => {
          downloadImage(uri);
        }, 3000);
      }

      // Log error to Sentry, but only if it's not a 404 (handled case)
      const found = !notFound;
      if (found) console.error(error);
    }
  };

  useEffect(() => {
    if (cache.current.has(uri)) {
      const imageUrl = cache.current.get(uri);
      setImageUrl(imageUrl);
    } else {
      downloadImage(uri);
    }
  }, []);

  return imageUrl;
};
