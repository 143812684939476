import React, { forwardRef } from "react";

interface Props {
  label?: string;
  name: string;
  required?: boolean;
  requiredMessage?: string;
  className?: string;
  register: any;
  errors: any;
  defaultValue?: string;
}

export const DateInput = forwardRef<HTMLInputElement, Props>(
  ({ label, name, required, requiredMessage = "This field is required.", className, register, errors }, fwRef: any) => {
    const isError = errors[name];

    const showPicker = () => {
      if (fwRef && fwRef.current) fwRef.current.focus(); // Focus on the date input element to trigger the native date picker
    };

    const { ref, ...rest } = register(name, { required });

    return (
      <div className={`form-control w-full ${className ?? ""}`}>
        {label && (
          <label className="label" htmlFor={name}>
            <span className="label-text">
              {label} {required ? <span className="text-warning">*</span> : <></>}
            </span>
          </label>
        )}
        <input
          id={name}
          type="date"
          className={`input-bordered input w-full bg-slate-100 ${isError ? "input-error" : ""}`}
          {...rest}
          ref={(e) => {
            ref(e);
            fwRef.current = e;
          }}
        />
        {isError && <span className="pl-1 pt-1 text-xs text-warning">{requiredMessage}</span>}
      </div>
    );
  },
);
