import { CrossIcon } from "@components/Icons/Cross";
import { Input } from "@components/Input";
import { FC } from "react";
import { useTranslation } from "@helpers/useTranslation";

type Props = {
  questionIndex: number;
  errors: any;
  register: any;
  correctAnswer?: number;
  answer1?: string;
  answer2?: string;
  answer3?: string;
  answer4?: string;
  updateCorrectAnswer?: (correctAnswer: number) => void;
  setActiveQuestionToRemove?: (index: number) => void;
  moveItem?: (index: number, direction: "up" | "down") => void;
  isLast?: boolean;
  isFirst?: boolean;
};

export const Question: FC<Props> = ({
  questionIndex,
  correctAnswer,
  errors,
  register,
  setActiveQuestionToRemove,
  updateCorrectAnswer,
  moveItem,
  isLast,
  isFirst,
}) => {
  const { t } = useTranslation("admin");
  return (
    <>
      <div className="mb-8">
        <div className="mb-4 flex flex-row">
          <Input
            label={`${t("question")} ${questionIndex + 1}`}
            name={`questions.${questionIndex}.question`}
            required
            requiredMessage={t("fieldRequired")}
            errors={errors}
            register={register}
            renderTopRight={() => (
              <div className="font-bold text-neutral">
                <button
                  type="button"
                  disabled={isFirst}
                  className={`mx-2 ${isFirst ? "text-gray-200" : ""}`}
                  onClick={() => moveItem(questionIndex, "up")}
                >
                  ↑
                </button>
                <button
                  type="button"
                  disabled={isLast}
                  className={`mx-2 ${isLast ? "text-gray-200" : ""}`}
                  onClick={() => moveItem(questionIndex, "down")}
                >
                  ↓
                </button>
              </div>
            )}
          />
          <button
            className="btn-outline btn-error btn-xs btn-circle btn ml-4 mt-12"
            type="button"
            onClick={() => setActiveQuestionToRemove(questionIndex)}
          >
            <CrossIcon className="h-4 w-4" strokeWidth={2} />
          </button>
        </div>
        <div className="mb-4 flex flex-row justify-between">
          <span className="mr-2 text-sm">{t("answer")}</span>
          <span className="ml-2 text-sm">{t("isCorrect")}</span>
        </div>
        <div className="mb-2 flex flex-row">
          <Input
            className={`${correctAnswer === 0 ? "border border-b-4 border-success" : ""}`}
            name={`questions.${questionIndex}.answer0`}
            required
            requiredMessage={t("fieldRequired")}
            errors={errors}
            register={register}
          />
          <input
            type="checkbox"
            checked={correctAnswer === 0}
            onClick={() => updateCorrectAnswer(0)}
            className={`${correctAnswer === 0 ? "checkbox-success" : ""} checkbox ml-6 self-center`}
          />
        </div>
        <div className="mb-2 flex flex-row">
          <Input
            className={`${correctAnswer === 1 ? "border border-b-4 border-success" : ""}`}
            name={`questions.${questionIndex}.answer1`}
            required
            requiredMessage={t("fieldRequired")}
            errors={errors}
            register={register}
          />
          <input
            type="checkbox"
            checked={correctAnswer === 1}
            onClick={() => updateCorrectAnswer(1)}
            className={`${correctAnswer === 1 ? "checkbox-success" : ""} checkbox ml-6 self-center`}
          />
        </div>
        <div className="mb-2 flex flex-row">
          <Input
            className={`${correctAnswer === 2 ? "border border-b-4 border-success" : ""}`}
            name={`questions.${questionIndex}.answer2`}
            required
            requiredMessage={t("fieldRequired")}
            errors={errors}
            register={register}
          />
          <input
            type="checkbox"
            checked={correctAnswer === 2}
            onClick={() => updateCorrectAnswer(2)}
            className={`${correctAnswer === 2 ? "checkbox-success" : ""} checkbox ml-6 self-center`}
          />
        </div>
        <div className="mb-2 flex flex-row">
          <Input
            className={`${correctAnswer === 3 ? "border border-b-4 border-success" : ""}`}
            name={`questions.${questionIndex}.answer3`}
            required
            requiredMessage={t("fieldRequired")}
            errors={errors}
            register={register}
          />
          <input
            type="checkbox"
            checked={correctAnswer === 3}
            onClick={() => updateCorrectAnswer(3)}
            className={`${correctAnswer === 3 ? "checkbox-success" : ""} checkbox ml-6 self-center`}
          />
        </div>
        <span className="mt-2" />
      </div>
    </>
  );
};
