import { useEffect, useState } from "react";
import { User } from "firebase/auth";

export const useClaims = (user: User | null): [Claims, boolean, Error | undefined] => {
  const [loading, setLoading] = useState(true);
  const [claims, setClaims] = useState<Claims | undefined>();
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    const fetchClaims = async () => {
      if (user) {
        try {
          const tokenResult = await user.getIdTokenResult();
          setClaims(tokenResult.claims as Claims);
        } catch (e) {
          console.error("Error fetching claims:", e);
          setError(e);
        } finally {
          setLoading(false);
        }
      } else {
        setClaims(undefined);
        setLoading(false);
      }
    };

    fetchClaims();
  }, [user]);

  return [claims, loading, error];
};
