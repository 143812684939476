import { SpinnerIcon } from "@components/Icons/Spinner";
import { useEffect, useState } from "react";

export const Spinner = ({ deflashTimer = 200 }) => {
  const [deflashExpired, setDeflashExpired] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDeflashExpired(true);
    }, deflashTimer);
    return () => clearTimeout(timer);
  }, []);

  if (!deflashExpired) return null;
  return (
    <div className="flex w-full justify-center">
      <SpinnerIcon className="-mt-16 h-screen h-16 w-16 max-w-sm" />
    </div>
  );
};
