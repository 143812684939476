import { collection } from "@firebase/firestore";
import { useContext, useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "./firebase";

export const useSimpleCollection = <T>(path: string): [T[], boolean, Error] => {
  const { firestore } = useContext(FirebaseContext);
  const [snapshot, loading, error] = useCollection(collection(firestore, path));
  const data = useMemo(() => {
    if (!snapshot) return [];
    return snapshot.docs.map((doc) => {
      const data = doc.data();
      const dataWithId = { ...data, id: doc?.id } as T;
      return dataWithId;
    });
  }, [snapshot]);

  return [data, loading, error];
};
