import { QuestionMarkIcon } from "@components/Icons/QuestionMark";
import { FC } from "react";
import { FieldErrors, FieldValues, UseFormGetValues, UseFormSetValue } from "react-hook-form";

type Props = {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  errors?: FieldErrors<FieldValues>;
  className?: string;
  autoComplete?: string;
  disabled?: true;
  tooltip?: string;
  onClick?: () => void;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
};

export const Toggle: FC<Props> = ({
  label,
  name,
  required,
  requiredMessage,
  errors,
  className,
  tooltip,
  getValues,
  setValue,
  onClick,
  ...props
}) => {
  const errorMessage = (errors[name]?.message || requiredMessage) as string;

  return (
    <div className="form-control mt-4 w-full">
      <label className="label cursor-pointer" htmlFor={name}>
        {tooltip && (
          <div className="tooltip-tight-right tooltip tooltip-top flex items-end" data-tip={tooltip}>
            <QuestionMarkIcon className="h-5 h-5 text-neutral opacity-60" strokeWidth={0.2} />
          </div>
        )}
        <div className="label-text mr-3 text-sm">
          <span className="label-text">
            {label} {required ? <span className="text-warning">*</span> : <></>}
          </span>
        </div>
        <input
          id={name}
          type="checkbox"
          className={`toggle-primary toggle ${className ?? ""}`}
          checked={getValues(name)}
          onClick={(e) => {
            const value = (e.target as HTMLInputElement).checked;
            setValue(name, value);
            onClick && onClick();
          }}
          {...props}
        />
      </label>
      {errors[name] && <span className="pl-1 pt-2 text-xs text-warning">{errorMessage}</span>}
    </div>
  );
};
