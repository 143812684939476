import { FC } from "react";
import { IconProps } from "./IconProps";

export const CrossIcon: FC<IconProps> = ({ className, strokeWidth }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`${className ? className : "h-6 w-6"}}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth ?? 1.5} d="M6 18L18 6M6 6l12 12" />
  </svg>
);
