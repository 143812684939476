import { useContext, useEffect, useRef, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useEventNavigate } from "src/helpers/useEventNavigate";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const Intro = () => {
  const { t } = useTranslation("intro");
  const eventId = useEventId();
  const input1ref = useRef<HTMLInputElement>(null);
  const input2ref = useRef<HTMLInputElement>(null);
  const input3ref = useRef<HTMLInputElement>(null);
  const input4ref = useRef<HTMLInputElement>(null);
  const refs = [undefined, input1ref, input2ref, input3ref, input4ref];
  const [currentIndex, setCurrentIndex] = useState(0);
  const { functions } = useContext(FirebaseContext);
  const [appData, setAppData] = usePersistedState();
  const [invalidPin, setInvalidPin] = useState(false);
  const [event] = useEvent();

  useEffect(() => {
    const pin = appData?.pin;
    if (pin && event) {
      setInput1(pin[0]);
      setInput2(pin[1]);
      setInput3(pin[2]);
      setInput4(pin[3]);
      setLoading(true);
      toast(t("autoSubmit"), "🎉", "success", 3000);
      setTimeout(() => {
        onSubmit(pin);
      }, 3000);
    }
  }, [appData, event]);

  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");

  const navigate = useEventNavigate();

  const focusNext = (key: string) => {
    // Only valid key enteries are numbers or backspace
    if (key !== "Backspace" && isNaN(parseInt(key))) {
      return;
    }

    // Treat entering last digit the same as clicking submit
    if (currentIndex === 4 && key !== "Backspace") {
      onSubmit();
      return;
    }

    const newIndex = key === "Backspace" ? currentIndex - 1 : currentIndex + 1;
    const newIndexBounded = Math.min(Math.max(newIndex, 1), 4);
    setCurrentIndex(newIndexBounded);
  };

  const focusCurrent = () => {
    if (refs[currentIndex]?.current) {
      refs[currentIndex].current.focus();
      refs[currentIndex].current.select();
    }
  };

  useEffect(() => {
    focusCurrent();
    setInvalidPin(false);
  }, [currentIndex]);

  const [validatePin] = useHttpsCallable(functions, "validatePin");
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const onSubmit = async (pinFromUrl?: string) => {
    const allInputsValid = [input1, input2, input3, input4].every((input) => !isNaN(parseInt(input)));
    const pinExistsInUrl = pinFromUrl !== undefined;
    if (!allInputsValid && !pinExistsInUrl) {
      toast(t("invalidPinCode"), "🤷‍♂️", "error");
      setInvalidPin(true);
      return;
    }

    setInvalidPin(false);

    const pin = pinExistsInUrl ? pinFromUrl : `${input1}${input2}${input3}${input4}`;
    try {
      setLoading(true);
      const data = await validatePin({ eventId, pin });
      const valid = ((data?.data as any)?.valid || false) as boolean;
      if (valid) {
        let nextPage = "/select";
        if (isPromo) nextPage = "/teams"; // in Promo events we don't allow direct uploads (only challenges) so we skip to teams
        if (isAsseco) nextPage = "/selectteam"; // TODO: temp hot fix for specific event, remove after event
        navigate(nextPage);
        setAppData({ ...appData, pin });
      } else {
        setInvalidPin(true);
        focusCurrent();
        toast(t("invalidPinCode"), "🤷‍♂️", "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const isDemo = eventId === "demo";
  const isPromo = event?.isPromo;
  const isAsseco = eventId === "asseco2024"; // TODO: temp hot fix for specific event, remove after event

  return (
    <div className="flex flex-col">
      <main className="flex-1 flex-col p-4">
        <div>
          <h1 className="mt-12 mb-8 text-center  text-4xl font-semibold">{t("title")}</h1>
          <h1 className="mt-16 mb-8 text-center text-2xl font-semibold">{event?.name ?? ""}</h1>
          <div className="w-full px-4 pt-4 text-center">{event?.about || t("about")}</div>
        </div>
        <h2 className="mt-16 mb-8 text-center text-2xl font-semibold">PIN</h2>
        <div className="mt-8 flex justify-center">
          <input
            ref={input1ref}
            aria-label="PIN digit 1"
            maxLength={1}
            type="text"
            inputMode="numeric"
            pattern="[0-9]"
            placeholder=""
            value={input1}
            onFocus={() => setCurrentIndex(1)}
            onChange={(e) => setInput1(e.target.value)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input2ref}
            aria-label="PIN digit 2"
            maxLength={1}
            type="text"
            inputMode="numeric"
            value={input2}
            onChange={(e) => setInput2(e.target.value)}
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(2)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input3ref}
            aria-label="PIN digit 3"
            maxLength={1}
            type="text"
            value={input3}
            onChange={(e) => setInput3(e.target.value)}
            inputMode="numeric"
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(3)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
          <input
            ref={input4ref}
            aria-label="PIN digit 4"
            maxLength={1}
            type="text"
            inputMode="numeric"
            value={input4}
            onChange={(e) => setInput4(e.target.value)}
            pattern="[0-9]"
            placeholder=""
            onFocus={() => setCurrentIndex(4)}
            className="input-bordered input-info input mx-4 w-12 text-center"
            onKeyUp={(e) => focusNext(e.key)}
          />
        </div>
        {invalidPin && (
          <div className="mt-4 flex justify-center">
            <span className="text-xs text-error">{t("invalidPinCode")}</span>
          </div>
        )}
        <div className="mt-20 flex justify-center">
          <button
            disabled={!input1 || !input2 || !input3 || !input4 || loading}
            className={`btn-primary btn mb-4 px-24 ${loading ? "loading" : ""}`}
            onClick={() => onSubmit()}
          >
            {t("start")}
          </button>
        </div>
        {isDemo && (
          <div className="sticky bottom-5 mt-12 mb-12 flex justify-center">
            <div className="flex max-w-sm flex-col items-center justify-center rounded-lg bg-error px-12 py-4">
              <div className="mb-4 text-center text-lg text-white">⚠ {t("thisIsDemo")} ⚠</div>
              <div className="text-center text-lg text-white">
                <span className="mr-4">{t("pinCodeIs")}</span>
                <span className="text-4xl font-bold">1234</span>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};
