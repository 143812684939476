import { Footer } from "@components/Footer";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { Link } from "react-router-dom";
import { useTranslatedKey } from "@helpers/useTranslatedKey";

type Props = {
  challenge: QuizChallenge;
  solution: QuizSolution;
};

export const Quiz: FC<Props> = ({ challenge, solution }) => {
  const { t } = useTranslation("challenges");
  const [message, setMessage] = useState("");

  const answers = solution?.answers || [];

  const isCorrect = (correctAnswer: number, answerIndex: number) => {
    if (answers.length < answerIndex) return false;
    return correctAnswer === answers[answerIndex];
  };

  const answerExists = (answerIndex: number) => {
    if (answers.length < answerIndex) return false;
    return answers[answerIndex] !== undefined;
  };

  useEffect(() => {
    if (solution) {
      const correctQuestions = challenge.questions.reduce((acc, q, index) => {
        if (isCorrect(q.correctAnswer, index)) {
          return acc + 1;
        }
        return acc;
      }, 0);

      const allQuestions = challenge.questions.length;
      setMessage(`${t("answered")} ${correctQuestions} / ${allQuestions} ${t("correctly")}!`);
    }
  }, [solution]);

  const { getTranslatedValue } = useTranslatedKey();
  const about = getTranslatedValue(challenge, "about");
  const copy = getTranslatedValue(challenge, "copy");

  if (solution?.completed) {
    return (
      <main>
        <div className="flex max-w-lg flex-col justify-center px-4">
          <h1 className="mt-16 px-10 text-center text-3xl font-semibold">{t("quizChallengeCompleted")}</h1>
          <div className="mt-4 text-center text-lg">{t("challengeDoneSummary")}</div>
        </div>
        <p className="mt-16 mb-10 px-10 text-center text-lg font-bold">{message}</p>
        {challenge.questions.map((q, index) => {
          if (!answerExists(index)) return <></>;

          const correct = isCorrect(q.correctAnswer, index);
          let correctAnswer = "";
          if (q.correctAnswer === 0) correctAnswer = q.answer0;
          if (q.correctAnswer === 1) correctAnswer = q.answer1;
          if (q.correctAnswer === 2) correctAnswer = q.answer2;
          if (q.correctAnswer === 3) correctAnswer = q.answer3;

          let yourAnswer = "";
          if (answers[index] === 0) yourAnswer = q.answer0;
          if (answers[index] === 1) yourAnswer = q.answer1;
          if (answers[index] === 2) yourAnswer = q.answer2;
          if (answers[index] === 3) yourAnswer = q.answer3;

          if (challenge.showCorrectAnswers) {
            return (
              <div key={q.question} className="text-md my-24 px-10 text-center">
                <p className="font-semibold">{getTranslatedValue(q, "question")}</p>
                <div className="mt-4 font-medium">{`${t("correctAnswer")}: ${correctAnswer}`}</div>
                <div className="mt-4 text-xs">
                  {`${t("yourAnswer")}: ${yourAnswer}`}
                  <span className="ml-4">{correct ? `✅` : `❌`}</span>
                </div>
              </div>
            );
          }
          return (
            <div key={q.question} className="text-md my-24 px-10 text-center">
              <p className=" font-semibold">{getTranslatedValue(q, "question")}</p>
              <div className=" mt-2">
                <span className="text-sm font-light">{correct ? t("correct") : t("incorrect")} </span>
                <span className="ml-4">{correct ? `✅` : `❌`}</span>
              </div>
            </div>
          );
        })}
        <Footer />
      </main>
    );
  }
  return (
    <>
      <main>
        <div className="px-6 py-4">
          <h1 className="my-16 text-center  text-3xl font-semibold">{about}</h1>
          <div className="mt-4 mb-8 flex flex-col items-center">
            <div className="mx-6">
              <div className="text-center text-xl">{copy}</div>
              <div className="my-12 flex justify-center">
                <Link to="0">
                  <button className="btn-primary btn my-6">{t("start")}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
