import { useEffect } from "react";
import { usePersistedState } from "./usePersistedState";
import { useEventNavigate } from "./useEventNavigate";

export const useHasNoPin = () => {
  const [appData] = usePersistedState();
  const navigate = useEventNavigate();

  useEffect(() => {
    if (!appData) return;

    const hasNoPin = !appData?.pin;
    if (hasNoPin) navigate("/intro");
  }, [appData]);
};
