import { ConfirmModal } from "@components/ConfirmModal";
import { Input } from "@components/Input";
import { Textarea } from "@components/Input/Textarea";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { FC, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "@helpers/useTranslation";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useToast } from "src/helpers/useToast";
import { Question } from "./Question";
import { Modal } from "@components/Modal";
import { DeleteIcon } from "@components/Icons/Delete";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslatedKey } from "@helpers/useTranslatedKey";
import { reorder } from "@helpers/reorder";
import { uid } from "@helpers/uid";
import { QuestionMarkIcon } from "@components/Icons/QuestionMark";

type Props = {
  challenge: (Challenge & Solution) | undefined;
  close: () => void;
};

export const EditModal: FC<Props> = ({ challenge, close }) => {
  const { t } = useTranslation("admin");
  const toast = useToast();
  const { getKeyName, getTranslatedValue } = useTranslatedKey();
  const defaultChallengeType = challenge?.type || "photo";
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: (data) => {
      if (data.type === "quiz") {
        const questions = data.questions;
        const hasEmptyQuestion = questions.some((question) => getTranslatedValue(question, "question") === "");
        if (hasEmptyQuestion) {
          toast(t("fieldRequired"), "🤷‍♂️", "error");
          return {
            values: data,
            errors: {
              questions: {
                message: t("fieldRequired"),
              },
            },
          };
        }
      }
      return {
        values: data,
        errors: {},
      };
    },
    defaultValues: useMemo(() => {
      const challengeQuestions = (challenge as any)?.questions || [];
      const questions = challengeQuestions.map((question) => ({
        ...question,
        question: getTranslatedValue(question, "question") || "",
        answer0: getTranslatedValue(question, "answer0") || "",
        answer1: getTranslatedValue(question, "answer1") || "",
        answer2: getTranslatedValue(question, "answer2") || "",
        answer3: getTranslatedValue(question, "answer3") || "",
      }));
      const title = getTranslatedValue(challenge, "title") || "";
      const about = getTranslatedValue(challenge, "about") || "";
      const copy = getTranslatedValue(challenge, "copy") || "";
      const type = defaultChallengeType || "";
      const showCorrectAnswers = (challenge as QuizChallenge)?.showCorrectAnswers || false;
      const reward = challenge?.reward || 0;

      return { title, about, copy, type, reward, questions, showCorrectAnswers };
    }, [challenge]),
  });

  const [activeTab, setActiveTab] = useState<number | undefined>(undefined);

  const { firestore } = useContext(FirebaseContext);
  const eventId = useEventId();
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const openConfirmDelete = () => setShowConfirmDelete(true);
  const closeConfirmDelete = () => setShowConfirmDelete(false);

  const [event] = useEvent();
  //https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const onDelete = async () => {
    setDeleting(true);
    const docRef = doc(firestore, `events/${eventId}/challenges/${challenge.id}`);
    await deleteDoc(docRef);

    // In some edge-cases challenge order normalisation doesn't exist, if so we can ignore the below operation
    if (event.order) {
      const newOrder = event.order.filter((id) => id !== challenge.id);
      const eventRef = doc(firestore, `events/${eventId}`);
      await updateDoc(eventRef, { order: newOrder });
    }

    setDeleting(false);
    toast(t("deleted"), "👍");
    close();
  };

  const onSubmit = async ({ reward, title, about, copy, questions, showCorrectAnswers }) => {
    setUpdating(true);
    const payload = { reward: parseInt(reward), title, about, copy, showCorrectAnswers };
    payload[getKeyName("title")] = title;
    payload[getKeyName("about")] = about;
    payload[getKeyName("copy")] = copy;
    questions = (questions || []).map((question) => ({
      ...question,
      [getKeyName("question")]: question.question,
      [getKeyName("answer0")]: question.answer0,
      [getKeyName("answer1")]: question.answer1,
      [getKeyName("answer2")]: question.answer2,
      [getKeyName("answer3")]: question.answer3,
    }));
    payload["questions"] = questions;

    const docRef = doc(firestore, `events/${eventId}/challenges/${challenge.id}`);
    await setDoc(docRef, payload, { merge: true });
    setUpdating(false);
    toast(t("updated"), "👍");
    close();
  };
  const questions: QuizQuestion[] = getValues("questions") || [];

  const onNewQuestionClick = () => {
    if (isTrial) return;
    const newQuestions = [
      ...questions,
      { id: uid(), question: "", answer0: "", answer1: "", answer2: "", answer3: "", correctAnswer: 0 },
    ];
    setValue("questions", newQuestions);
    forceUpdate();
  };

  const challengeType = watch("type");

  const isQuiz = useMemo(() => challengeType === "quiz", [challengeType]);
  useEffect(() => {
    if (isQuiz) setActiveTab(1);
  }, [isQuiz]);

  const isPhoto = useMemo(() => ["photo", "greeting", "video"].includes(challengeType), [challengeType]);
  useEffect(() => {
    if (isPhoto) setActiveTab(1);
  }, [isPhoto]);

  const isGreeting = useMemo(() => challengeType === "greeting", [challengeType]);

  const [activeQuestionToRemove, setActiveQuestionToRemove] = useState<number | undefined>(undefined);
  const onRemove = (index: number) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setValue("questions", newQuestions);
    forceUpdate();
  };

  const tabWidth = challengeType === "quiz" ? "w-1/3" : "w-1/2";

  const moveItem = (index: number, direction: "up" | "down") => {
    if (direction === "up" && index > 0) {
      const newOrder = reorder(questions, index, index - 1);
      setValue("questions", newOrder);
    } else if (direction === "down" && index < questions.length - 1) {
      const newOrder = reorder(questions, index, index + 1);
      setValue("questions", newOrder);
    }
    forceUpdate();
  };

  const toggleHideChallenge = async (challengeId: string, value: boolean) => {
    const docRef = doc(firestore, `events/${eventId}/challenges/${challengeId}`);
    await updateDoc(docRef, { hidden: value });
  };

  const isTrial = event?.isTrial || false;

  return (
    <>
      <ConfirmModal
        messageCopy={t("areYouSureDeleteChallenge")}
        show={showConfirmDelete}
        onCancel={closeConfirmDelete}
        onConfirm={onDelete}
      />
      <ConfirmModal
        show={activeQuestionToRemove !== undefined}
        onCancel={() => setActiveQuestionToRemove(undefined)}
        onConfirm={() => {
          if (activeQuestionToRemove !== undefined) onRemove(activeQuestionToRemove);
        }}
      />
      <Modal show={!!challenge} onCancel={close}>
        <h3 className="mt-6 text-lg font-bold">
          {t("edit")}: {getTranslatedValue(challenge, "title")}
        </h3>
        <div className="mt-2 text-sm">{t(challengeType)}</div>
        <form onSubmit={handleSubmit(onSubmit)} className="overflow-y pt-4">
          <div className="tabs mb-4 mt-8">
            <a
              className={`tab-bordered tab ${activeTab && activeTab === 1 ? "tab-active" : ""} ${tabWidth}`}
              onClick={() => setActiveTab(1)}
            >
              {t("details")}
            </a>
            <a
              className={`tab-bordered tab ${tabWidth} ${challengeType === "quiz" ? "" : "hidden"} ${
                activeTab && activeTab === 2 ? "tab-active" : ""
              } w-1/3`}
              onClick={() => setActiveTab(2)}
            >
              {t("questions")}
            </a>
            <a
              className={`tab-bordered tab ${activeTab && activeTab === 3 ? "tab-active" : ""} ${tabWidth}`}
              onClick={() => setActiveTab(3)}
            >
              {t("deleteAndHide")}
            </a>
          </div>
          <div className={`${!activeTab || activeTab === 1 ? "" : "hidden"}`}>
            <Input
              label={t("challenge")}
              name="title"
              required
              requiredMessage={t("fieldRequired")}
              errors={errors}
              register={register}
              className="max-w-lg"
            />
            <span className="mt-2" />
            <Textarea
              label={t("about")}
              name="about"
              rows={2}
              errors={errors}
              register={register}
              className="max-w-lg"
            />
            {!isGreeting && (
              <Textarea
                label={t("copy")}
                name="copy"
                required
                rows={4}
                requiredMessage={t("fieldRequired")}
                errors={errors}
                register={register}
                className="max-w-lg"
              />
            )}
            <Input
              label={t("reward")}
              name="reward"
              type="number"
              required
              requiredMessage={t("fieldRequired")}
              errors={errors}
              register={register}
              className="max-w-lg"
            />
            {challengeType === "quiz" && (
              <div className="form-control mt-4 w-full">
                <label className="label cursor-pointer">
                  <span className="label-text mr-3 text-sm">{t("showCorrectAnswers")}</span>
                  <input
                    type="checkbox"
                    className="toggle-primary toggle"
                    checked={getValues(`showCorrectAnswers`)}
                    onClick={(e) => {
                      const value = (e.target as any).checked;
                      setValue(`showCorrectAnswers`, value);
                      forceUpdate();
                    }}
                  />
                </label>
              </div>
            )}
          </div>
          <div className={`${activeTab === 2 ? "" : "hidden"}`}>
            <DragDropContext onDragEnd={() => {}}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {questions.map(({ question, id }, i) => {
                      const key = id ?? question ?? i;
                      return (
                        <Draggable key={key} draggableId={key} index={i}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div {...provided.dragHandleProps}>
                                <Question
                                  moveItem={moveItem}
                                  isFirst={i === 0}
                                  isLast={i === questions.length - 1}
                                  key={i}
                                  questionIndex={i}
                                  correctAnswer={getValues(`questions.${i}.correctAnswer`)}
                                  updateCorrectAnswer={(correctAnswer) => {
                                    setValue(`questions.${i}.correctAnswer`, correctAnswer);
                                    forceUpdate();
                                  }}
                                  register={register}
                                  errors={errors}
                                  setActiveQuestionToRemove={(index) => setActiveQuestionToRemove(index)}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="flex justify-center">
              <div className="tooltip-premium tooltip text-neutral" data-tip={t("premiumFeature")}>
                <button
                  onClick={() => onNewQuestionClick()}
                  className={`btn-outline btn-primary btn my-6 h-10 w-28 text-lg ${isTrial ? "btn-disabled" : ""}`}
                  disabled={isTrial}
                  type="button"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div className={`${!activeTab || activeTab === 3 ? "" : "hidden"}`}>
            <div className="mt-12 mb-16 flex flex-col items-center justify-center">
              <p className="mb-4 text-center">{t("areYouSureHideChallenge")}</p>
              <div className="flex flex-row items-center">
                <label className="label cursor-pointer" htmlFor={"hide-toggle"}>
                  <div className="label-text mr-3 text-sm">
                    <span className="label-text">{t("hide")}</span>
                  </div>
                  <input
                    id={"hide-toggle"}
                    type="checkbox"
                    className={`toggle-primary toggle`}
                    defaultChecked={challenge.hidden ?? false}
                    onClick={(e) => {
                      const value = (e.target as HTMLInputElement).checked;
                      toggleHideChallenge(challenge.id, value);
                    }}
                  />
                </label>
                <div className="tooltip-tight-right tooltip tooltip-top flex items-end" data-tip={t("hideTooltip")}>
                  <QuestionMarkIcon className="h-5 h-5 text-neutral opacity-60" strokeWidth={0.2} />
                </div>
              </div>
              <div className="my-2 my-8 h-0.5 w-full bg-gray-200" />
              <p className="mb-12 text-center">{t("areYouSureDeleteChallenge")}</p>
              <button
                type="button"
                disabled={deleting}
                onClick={openConfirmDelete}
                className={`inline-flex w-full max-w-3xs items-center justify-center rounded-md border border-red-600 bg-white px-5 py-2 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-600 hover:text-white ${
                  deleting ? "loading" : ""
                }`}
              >
                <DeleteIcon className="mr-2 h-4 h-4" />
                {t("delete")}
              </button>
            </div>
          </div>
          <div>
            <div className="mt-8 mb-2 flex flex-col">
              <button
                type="submit"
                disabled={updating}
                className={`inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                  updating ? "loading" : ""
                }`}
              >
                {t("save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
                onClick={close}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
