import { useBranding } from "src/helpers/useBranding";

export const Contact = () => {
  const { emailDomain } = useBranding();
  return (
    <div
      style={{ backgroundImage: `url(/bg-wedding.webp)` }}
      className={`h-screen justify-center overflow-hidden bg-slate-800 bg-cover bg-center bg-blend-overlay`}
    >
      <div className="flex justify-center px-4">
        <div className="mb-4 mt-16 flex max-w-sm flex-col justify-between rounded-lg bg-slate-100 p-6">
          <div>
            <div className="px-8 pt-8 pb-4 text-center font-bold">
              <h3 className="text-lg font-bold">Get in touch!</h3>
              <p className="py-4">
                <span className="mb-4">Leave your email below and we will follow-up</span>
              </p>
            </div>
            <form className="text-center" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
              <div className="hidden">
                <label>
                  Don’t fill this out if you’re human: <input name="bot-field" />
                </label>
              </div>
              <label className="label" htmlFor="email-input">
                Your email:<span className="text-warning">*</span>
              </label>
              <input
                id="email-input"
                name="email"
                type="email"
                required
                placeholder="e.g.: johndoe@example.com"
                className="input-bordered input mb-4 w-full"
              />
              <button type="submit" className="btn-primary btn ml-4 mt-4">
                Contact
              </button>
            </form>
          </div>
          <div className="mt-24 flex flex-col items-center justify-center text-sm text-gray-600">
            <div className="mb-4 font-semibold">Or reach out directly at:</div>
            <span className="blockspam" aria-hidden="true">
              __
            </span>{" "}
            hello@
            <span className="blockspam" aria-hidden="true">
              ----
            </span>
            {emailDomain}
            <div className="mt-4">
              <a href="https://www.instagram.com/tuokisapp">
                <span className="text-primary underline">@tuokisapp</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
