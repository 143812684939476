import { DownloadIcon } from "@components/Icons/Download";
import { ShareIcon } from "@components/Icons/Share";
import { Loading } from "@components/Loading";
import { OverlaySpinner } from "@components/Loading/OverlaySpinner";
import { Modal } from "@components/Modal";
import { Pictures } from "@components/Pictures";
import { PremiumModal } from "@components/PremiumModal/PremiumModal";
import { ShareModal } from "@components/ShareModal";
import { TopNav } from "@components/TopNav";
import { httpsCallable } from "firebase/functions";
import { FC, Suspense, lazy, useContext, useMemo, useState } from "react";
import { useTranslation } from "@helpers/useTranslation";
import { downloadAsLink } from "src/helpers/download";
import { FirebaseContext } from "src/helpers/firebase";
import { useEvent, useEventId } from "src/helpers/useEvent";
import { useSimpleCollection } from "src/helpers/useSimpleCollection";
import { dismiss, useToast } from "src/helpers/useToast";
import { BookIcon } from "@components/Icons/Book";
const GuestBook = lazy(() => import("@components/GuestBook/GuestBook"));

export const Gallery: FC = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();

  const [event, evtLoading] = useEvent();
  const [teams, tLoading] = useSimpleCollection<Team>(`events/${eventId}/teams`);
  const [challenges, cLoading] = useSimpleCollection<Challenge>(`events/${eventId}/challenges`);
  const greetingChallenges = useMemo(() => challenges.filter((c) => c.type === "greeting"), [challenges]);
  const greetingChallengeId = greetingChallenges[0]?.id;

  const [loading, setLoading] = useState(false);
  const { functions } = useContext(FirebaseContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showGuestBookModal, setShowGuestBookModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [groupDownloadBy, setGroupDownloadBy] = useState<"challenge" | "team">("team");

  const toast = useToast();

  const downloadAll = async (regenerate = false) => {
    setLoading(true);

    try {
      if (regenerate) {
        toast(t("regenerateStarted"), "📸", "success", 60000);
      } else {
        toast(t("downloadStarted"), "📸", "success", 60000);
      }
      await download(regenerate);

      dismiss();
      if (regenerate) toast(t("allPhotosDownloadedRegenerate"), "📸", "success");
      else toast(t("allPhotosDownloaded"), "📸", "success");
    } catch (error) {
      dismiss();
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    }

    setLoading(false);
  };

  const download = async (regenerate) => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    try {
      const func = httpsCallable(functions, "downloadPhotos", { timeout: 8 * 60 * 1000 });
      const groupBy = groupDownloadBy;
      const result = await func({ eventId, regenerate, groupBy });
      const url = result?.data as string;

      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
      if (isIOS) {
        window.location.assign(url);
      } else {
        const filename = `${eventId}-photos-${groupDownloadBy}.zip`;
        downloadAsLink(url, filename);
      }
    } catch (error) {
      console.error(error);
      toast(t("errorDownloading"), "🤷‍♂️", "error");
    }
  };

  const isPremium = event?.isPremium || false;
  const isTrial = event?.isTrial || false;

  // TODO: change after mokyklai80
  // const isPromo = event?.isPromo || false;
  const isPromo = false;

  return (
    <main className="w-full">
      <OverlaySpinner loading={loading} />
      <TopNav isAdmin backLink="/admin" />
      <Modal show={showGuestBookModal} onCancel={() => setShowGuestBookModal(false)} className="z-40" size="sm">
        <h3 className="text-lg font-bold">{t("guestbook")}</h3>
        <p className="py-4">{`${t("downloadGuestbook")}`}</p>
        <div className="my-4 flex justify-center">
          <Suspense fallback={<></>}>
            <GuestBook
              event={event}
              teams={teams}
              challengeId={greetingChallengeId}
              onDownload={() => setShowGuestBookModal(false)}
            />
          </Suspense>
        </div>
      </Modal>
      <Modal show={showConfirmModal} onCancel={() => setShowConfirmModal(false)} className="z-40" size="sm">
        <h3 className="text-lg font-bold">{t("areYouSure")}</h3>
        <p className="py-4">{`${t("areYouSureDownloadAllPhotos")}`}</p>
        <div className="flex flex-col">
          <div className="mb-6 mt-2 flex w-full flex-col">
            <label className="label">
              <span className="label-text">{`${t("groupDownloadBy")}`}</span>
            </label>
            <select
              className="select-bordered select-secondary select"
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setGroupDownloadBy(e.target.value as "challenge" | "team");
              }}
            >
              <option selected={groupDownloadBy === "team"} value={"team"}>
                {t("groupByTeam")}
              </option>
              <option selected={groupDownloadBy === "challenge"} value={"challenge"}>
                {t("groupByChallenge")}
              </option>
            </select>
          </div>

          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-base-300 sm:w-auto"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              downloadAll();
              setShowConfirmModal(false);
            }}
          >
            {`${t("downloadAllPhotos")}`}
          </button>
          <div className="flex flex-col justify-center py-4 text-center">
            <div className="pb-2 text-xs">{`${t("notSeeingSomePhotosInArchive")}`}</div>
            <a
              className="mb-4 cursor-pointer whitespace-pre-wrap text-xs underline"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                downloadAll(true);
                setShowConfirmModal(false);
              }}
            >
              {`${t("cacheBustDownloadAllPhotos")}`}
            </a>
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
              onClick={() => setShowConfirmModal(false)}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      </Modal>
      <PremiumModal show={showPremiumModal} onClose={() => setShowPremiumModal(false)} />
      <ShareModal show={showShareModal} onClose={() => setShowShareModal(false)} />

      <div className="flex flex-col px-6 md:px-16">
        <div className="w-full">
          <h1 className="mt-16 mb-8 text-center text-3xl font-semibold">{t("gallery")}</h1>
          {teams.length === 0 ? (
            <>
              <p className="mt-16 mb-8 text-center text-lg font-light">{t("aboutGallery")}</p>
              <div className="text-center text-xl font-semibold">{t("noPicturesYet")}</div>
            </>
          ) : null}
        </div>
        <Loading loading={tLoading || cLoading || evtLoading}>
          {teams.length && event ? (
            <>
              <div className="flex justify-center">
                <button
                  type="button"
                  disabled={loading}
                  className={`inline-flex w-full max-w-xs justify-center rounded-md bg-primary px-2 py-1 text-sm text-white shadow-sm hover:bg-base-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 ${
                    loading ? "loading" : ""
                  }`}
                  onClick={() => (isPremium ? setShowConfirmModal(true) : setShowPremiumModal(true))}
                >
                  <div className="tooltip-premium tooltip" data-tip={t("premiumFeature")}>
                    <span className="align-center my-2 flex justify-center text-white">
                      <DownloadIcon className="mr-2 h-5 w-5" />
                      <span>{t("downloadAllPhotos")}</span>
                    </span>
                  </div>
                </button>
              </div>
              <div className="mt-2 flex justify-center ">
                <button
                  type="button"
                  disabled={loading}
                  className={`link rounded-lg px-2 ${loading ? "loading" : ""}`}
                  onClick={() => (isPremium ? setShowShareModal(true) : setShowPremiumModal(true))}
                >
                  <div className="tooltip-premium tooltip text-neutral" data-tip={t("premiumFeature")}>
                    <span className="align-center flex justify-center py-2 underline">
                      <ShareIcon className="mr-2 -mt-1 h-5 w-5" />
                      <span className="text-xs">{t("shareWithGuests")}</span>
                    </span>
                  </div>
                </button>
              </div>
              {isPromo ? null : (
                <div className="mt-2 flex justify-center ">
                  <button
                    type="button"
                    onClick={() => (isPremium ? setShowGuestBookModal(true) : setShowPremiumModal(true))}
                  >
                    <div className="tooltip-premium tooltip text-neutral" data-tip={t("premiumFeature")}>
                      <span className="align-center flex justify-center py-2 underline">
                        <BookIcon className="mr-2 -mt-1 h-5 w-5" />
                        <span className="text-xs">{t("guestbook")}</span>
                      </span>
                    </div>
                  </button>
                </div>
              )}
              <Pictures
                eventName={event?.name}
                challenges={challenges}
                teams={teams}
                eventId={eventId}
                allowDownload={!isTrial}
                allowDelete={true}
              />
            </>
          ) : null}
        </Loading>
      </div>
    </main>
  );
};
