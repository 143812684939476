import { QuestionMarkIcon } from "@components/Icons/QuestionMark";
import { FC } from "react";

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  errors?: any;
  register?: any;
  hidden?: boolean;
  type?: string;
  className?: string;
  pattern?: string;
  autoComplete?: string;
  title?: string;
  maxLength?: number;
  disabled?: true;
  tooltip?: string;
  renderTopRight?: () => JSX.Element;
}

export const Input: FC<Props> = ({
  label,
  name,
  required,
  placeholder,
  hidden,
  requiredMessage,
  errors,
  register,
  type = "text",
  className,
  tooltip,
  renderTopRight,
  ...props
}) => {
  const errorMessage = errors[name]?.message || requiredMessage;

  return (
    <div className={`form-control w-full ${hidden ? "hidden" : ""}`}>
      {label && (
        <div className="flex flex-row justify-between">
          <label className="label" htmlFor={name}>
            <span className="label-text">
              {label} {required ? <span className="text-warning">*</span> : <></>}
            </span>
          </label>
          {tooltip && (
            <div className="tooltip-tight-right tooltip tooltip-top mb-2 -mr-1 flex items-end" data-tip={tooltip}>
              <QuestionMarkIcon className="h-5 h-5 text-neutral opacity-60" strokeWidth={0.2} />
            </div>
          )}
          {renderTopRight && <div className="mb-2 -mr-1 flex items-end">{renderTopRight()}</div>}
        </div>
      )}
      <input
        id={name}
        type={type}
        placeholder={placeholder}
        className={`input-bordered input w-full bg-slate-100 dark:text-slate-500 ${className ?? ""}`}
        {...register(name, { required })}
        {...props}
      />
      {errors[name] && <span className="pl-1 pt-2 text-xs text-warning">{errorMessage}</span>}
    </div>
  );
};
