import { FirebaseCachedDeleteImage } from "@components/FirebaseCachedImage/FirebaseCachedDeleteImage";
import { useTranslation } from "@helpers/useTranslation";
import { PreviewTopBar } from "./PreviewTopBar";
import { PreviewFooter } from "./PreviewFooter";
import { FC } from "react";
import { MultiPicker } from "@components/ImagePicker";

type Props = {
  goToChallenges: () => void;
  goToHighscores: () => void;
};

export const PreviewUpload: FC<Props> = ({ goToChallenges, goToHighscores }) => {
  const { t } = useTranslation("upload");

  return (
    <div className="flex flex-col">
      <PreviewTopBar goBack={goToChallenges} />
      <main className="flex-1 flex-col">
        <div className="px-8">
          <h1 className="mt-12 mb-8 text-center text-3xl font-semibold">{t("title")}</h1>
          <p className="mb-8 text-center">{t("about")}</p>

          <div className="tooltip flex justify-center" data-tip={t("previewCopy")}>
            <div className="pointer-events-none opacity-50">
              <MultiPicker
                uploading={false}
                uploadLabel={t("uploadButton")}
                onNewImages={() => {}}
                setUploading={() => {}}
                setTotalUploadCount={() => {}}
              />
            </div>
          </div>

          <div className="mt-12 grid grid-cols-2 gap-4 md:grid-cols-4">
            <FirebaseCachedDeleteImage key={1} extraId={""} teamId={""} eventId={"demo"} alt="" onDelete={() => {}} />
          </div>
        </div>
      </main>
      <PreviewFooter
        goToChallenges={goToChallenges}
        goToHighscores={goToHighscores}
        goToUpload={() => {}}
        activePage={"upload"}
      />
    </div>
  );
};
