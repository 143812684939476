import { getDownloadURL, ref } from "firebase/storage";
import { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "src/helpers/firebase";
import { ImageCacheContext } from "src/helpers/imageCache";

export const useFirebaseCachedVideoUrl = (uri: string, videoExists = false) => {
  const cache = useContext(ImageCacheContext);
  const { storage } = useContext(FirebaseContext);
  const [videoUrl, setVideoUrl] = useState<string>(cache.current.get(uri) ?? "");

  const downloadVideo = async (uri: string) => {
    try {
      const downloadRef = ref(storage, uri);
      const url = await getDownloadURL(downloadRef);
      cache.current.set(uri, url);
      setVideoUrl(url);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!videoExists) return;
    if (cache.current.has(uri)) {
      const videoUrl = cache.current.get(uri);
      setVideoUrl(videoUrl);
    } else {
      downloadVideo(uri);
    }
  }, []);

  return videoUrl;
};
