import { useParams } from "react-router-dom";
import { useSimpleDocument } from "./useSimpleDocument";

export const useEvent = (): [AppEvent, boolean, Error] => {
  const eventId = useEventId();
  const [event, loading, error] = useSimpleDocument<AppEvent>(`events/${eventId}`);
  return [event, loading, error];
};

export const useEventId = (defaultEventId?: string) => {
  const { eventId } = useParams();
  if (!eventId && defaultEventId) return defaultEventId;
  return eventId;
};
