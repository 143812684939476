import { Loading } from "@components/Loading";
import { TopNav } from "@components/TopNav";
import { useContext, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useTranslation } from "@helpers/useTranslation";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FirebaseContext } from "src/helpers/firebase";
import { useEventId } from "src/helpers/useEvent";
import { usePersistedState } from "src/helpers/usePersistedState";
import { useToast } from "src/helpers/useToast";

export const PaymentsSuccess = () => {
  const { t } = useTranslation("admin");
  const eventId = useEventId();
  const [searchParams] = useSearchParams();
  const orderToken = searchParams.get("order-token");
  const { auth, functions } = useContext(FirebaseContext);
  const [validateOrder, loading, error] = useHttpsCallable<unknown, unknown>(functions, "validateOrder");
  const navigate = useNavigate();
  const [appData, setAppData] = usePersistedState();
  const orderUuid = appData?.orderUuid;
  const orderId = appData?.orderId;
  const toast = useToast();
  const [seconds, setSeconds] = useState(7);
  const [isValidated, setValidated] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const returnBack = () => {
    const redirectBackUrl = appData?.redirectBackUrl;
    setAppData({
      ...appData,
      uid: auth.currentUser?.uid,
      isAdmin: true,
      orderUuid: undefined, // Clear order info
      orderId: undefined, // Clear order info
    });

    redirectBackUrl ? navigate(redirectBackUrl) : navigate(`/${eventId}/admin`);
  };

  useEffect(() => {
    if (seconds <= 0) returnBack();
  }, [seconds]);

  useEffect(() => {
    if (!orderToken) return;
    if (isValidated) return;
    validate();
  }, [orderToken, validateOrder]);

  const validate = async () => {
    try {
      const result = await validateOrder({ orderToken, orderUuid, orderId, eventId });
      if (result) {
        toast(t("paymentSuccess"), "🎉", "success");
      }
      setValidated(true);
    } catch (error) {
      console.error("Error occured during order validation", error);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  };

  useEffect(() => {
    if (error) {
      console.error("Error occured during order validation", error);
      toast(t("somethingWentWrong"), "🤷‍♂️", "error");
    }
  }, [error]);

  return (
    <div className="flex flex-col">
      <Loading loading={loading}>
        <TopNav isAdmin />
        <div className="flex h-screen flex-col overflow-hidden">
          <main className="flex-1 overflow-y-scroll p-4">
            {error ? (
              <>
                <div className="flex flex-col items-center justify-center">
                  <h1 className="mt-12 text-center text-xl">{t("somethingWentWrong")}</h1>
                  <button onClick={returnBack} className="btn-primary btn-ghost btn mt-12">{`${t(
                    "goBackFromPayments",
                  )} ${seconds}`}</button>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center">
                <h1 className="mt-12 text-center text-2xl">{t("paymentSuccess")}</h1>
                <div className="mt-4 text-center text-lg">{t("confirmPremium")}</div>
                <button onClick={returnBack} className="btn-primary btn-ghost btn mt-12">{`${t(
                  "goBackFromPayments",
                )} ${seconds}`}</button>
              </div>
            )}
          </main>
        </div>
      </Loading>
    </div>
  );
};
