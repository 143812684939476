import { useParams } from "react-router-dom";
import { useSimpleDocument } from "./useSimpleDocument";
import { useTeamId } from "./useTeam";

export const useSolution = <T>(): [T, boolean, Error] => {
  const { eventId, challengeId } = useParams();
  const teamId = useTeamId();
  const [solution, loading, error] = useSimpleDocument<T>(`events/${eventId}/teams/${teamId}/solutions/${challengeId}`);
  return [solution, loading, error];
};
