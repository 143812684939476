import { FC } from "react";
import { IconProps } from "./IconProps";

export const UploadIcon: FC<IconProps> = ({ className, strokeWidth }) => (
  <svg
    aria-hidden="true"
    className={`${className ? className : "h-6 w-6"}`}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth ?? 1.5}
      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
    ></path>
  </svg>
);
