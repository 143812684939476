import { useParams } from "react-router-dom";
import { useSimpleDocument } from "./useSimpleDocument";

export const useChallenge = <T>(): [T, boolean, Error] => {
  const { eventId, challengeId } = useParams();
  const [challenge, loading, error] = useSimpleDocument<T>(`events/${eventId}/challenges/${challengeId}`);
  return [challenge, loading, error];
};

export const useChallengeId = () => {
  const { challengeId } = useParams();
  return challengeId;
};
