import { useTranslation } from "./useTranslation";

export const useTranslatedKey = (namespace?: string) => {
  const { i18n } = useTranslation(namespace);
  const getKeyName = (keyBase: string) => `${keyBase}_${i18n.language}`;
  const getTranslatedValue = (obj: object, key: string) => {
    if (!obj) return null;
    const keyName = getKeyName(key);
    return obj[keyName] || obj[key];
  };
  return { getKeyName, getTranslatedValue };
};
