import { FC } from "react";
import { Image } from "./Swiper";
import { useFirebaseCachedImageUrl } from "@helpers/useFirebaseCachedImageUrl";
import { useFirebaseCachedVideoUrl } from "@helpers/useFirebaseCachedVideoUrl";

type Props = {
  galleryId?: string;
  image: Image;
  width: number;
  height: number;
  hasVideo?: boolean;
};

export const Picture: FC<Props> = ({ galleryId, image, width, height, hasVideo }) => {
  const lightUrl = useFirebaseCachedImageUrl(`${image.uri}_light`, image.uri);
  const videoUrl = useFirebaseCachedVideoUrl(`${image.uri}.mp4`, hasVideo);

  // Below is a hack to carry image info through alt attribute
  // required due to how the PhotoSwipe library has no easy way to pass this info otherwise
  const alt = JSON.stringify({ ...image, videoUrl });

  return (
    <div className="card w-40 rounded-lg ring ring-1 ring-base-300 lg:w-64">
      <a
        href={lightUrl}
        data-pswp-width={width}
        data-pswp-height={height}
        key={galleryId + "-" + image.uri}
        target="_blank"
        rel="noreferrer"
      >
        <img className="!mb-0 h-40 w-40 rounded-lg object-cover lg:h-64 lg:w-64" src={lightUrl} alt={alt} />
      </a>
    </div>
  );
};
